import { Feature } from 'geojson';
import LatLon from 'geodesy/latlon-spherical';

const toRadians = (degrees) => {
  return degrees * Math.PI / 180;
};

// Converts from radians to degrees.
const toDegrees = (radians) => {
  return radians * 180 / Math.PI;
}

export const getBearing = (startLat, startLng, destLat, destLng) => {
  startLat = toRadians(startLat);
  startLng = toRadians(startLng);
  destLat = toRadians(destLat);
  destLng = toRadians(destLng);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x = Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  const brng = Math.atan2(y, x);
  const degerees = toDegrees(brng);
  return (degerees + 360) % 360;
}

export const latLngToPoint = (latLng: LatLon, properties?: any): Feature => {
  return {
    type: 'Feature',
    properties,
    geometry: {
      coordinates: [latLng.lng, latLng.lat],
      type: 'Point'
    }
  }
}