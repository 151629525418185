import React, { Fragment, useContext, useEffect, useState } from 'react';
import LatLon from 'geodesy/latlon-spherical';
import { navigationTargetVar } from '../graphql/cache';
import {useQuery} from '@apollo/client';
import {GET_NAVIGATION_TARGET_QUERY} from '../graphql/pois';
import styled from 'styled-components';
import {DirectionsIcon, CloseIcon} from '../components/icons';
import {getBearing} from '../utils/geo';
import {IconButton} from '../components/IconButton';
import { UserPositionContext } from '../user/UserPositionProvider';

const NavigationContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  padding-right: ${props => props.theme.spacing.default}px;
`
const InfoList = styled.ul`
  list-style: none;
  font-size: ${props => props.theme.font.size.small};
  margin: 0 ${props => props.theme.spacing.default / 4}px;
  padding: 0;
`

const InfoListItem = styled.li`
  list-style: none;
  font-size: ${props => props.theme.font.size.small};
  margin: 0;
  line-height: 18px;
  display: flex;
  justify-content: left;
`

export const Navigation = () => {
  const navigationTargetData = useQuery(GET_NAVIGATION_TARGET_QUERY).data;
  const { position } = useContext(UserPositionContext)
  const target = navigationTargetData?.navigationTarget;
  const [distance, setDistance] = useState<number | null>(null)
  const [bearing, setBearing] = useState<number | null>(null)

  useEffect(() => {
    if(position && target) {
      const userPoint = new LatLon(position.coords.latitude, position.coords.longitude);
      const targetPoint = new LatLon(target.point.coordinates[1], target.point.coordinates[0]);
      setDistance(Math.round(userPoint.distanceTo(targetPoint)))
      setBearing(Math.round(getBearing(position.coords.latitude, position.coords.longitude, target.point.coordinates[1], target.point.coordinates[0])))
    }
  }, [target, position])

  return (
    <NavigationContainer>
      <DirectionsIcon />
      {
        target && (
          <Fragment>
            <InfoList>
              <InfoListItem>{target.name}</InfoListItem>
              <InfoListItem>{distance}m</InfoListItem>
              <InfoListItem>{bearing}&deg;</InfoListItem>
            </InfoList>
            <IconButton clickHandler={() => navigationTargetVar(undefined)} size={'sm'}>
              <CloseIcon />
            </IconButton>
          </Fragment>
        )
      }
    </NavigationContainer>
  )
}
