import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.background.dark};
  width: 100vw;
  height: ${props => props.theme.spacing.default*4}px;
  color: ${props => props.theme.colors.main};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  h1, h2, h3, h4 {
    margin: 0;
  }
`;

export const Heading = (props: any) => {
  return (
    <Container>
      {props.children}
    </Container>
  )
}
