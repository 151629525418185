import React, { useState } from 'react';
import maastokarttaVector from '../assets/mml-vector.json';

export interface MapStyleOption {
  id: 'maastokartta' | 'satellite',
  style: any
}

export const MapStyleOptions: MapStyleOption[] = [
  { id: 'maastokartta', style: maastokarttaVector },
  { id: 'satellite', style: 'mapbox://styles/command-tracker/cl4xzyo4w000r14qgp9y23u4j' },
]

export interface IMapStyleContext {
  selectedStyleOption: MapStyleOption,
  setSelectedStyleOption: (a: MapStyleOption) => void
}

export const MapStyleContext = React.createContext<IMapStyleContext>({
  selectedStyleOption: MapStyleOptions[0],
  setSelectedStyleOption: () => {}
});

export const MapStyleProvider = (props: any) => {
  const [selectedStyleOption, setSelectedStyleOption] = useState(MapStyleOptions[0]);

  return (
    //@ts-ignore
    <MapStyleContext.Provider value={{selectedStyleOption, setSelectedStyleOption}}>
      {props.children}
    </MapStyleContext.Provider>
  )
}
