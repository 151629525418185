import React from 'react';
import styled from 'styled-components';

const GridSelectorStyled = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.spacing.margin}px;
`

interface GridSelectorProps {
  children: any
}

export const GridSelector = (props: GridSelectorProps) => {

  return (
    <GridSelectorStyled>
      {props.children}
    </GridSelectorStyled>
  )
}
