import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, { MapLayerMouseEvent, MapRef, ViewState } from 'react-map-gl';
import { PageContent } from '../components/PageContent';
import { useQuery } from '@apollo/client';

import { GET_BODY_HEIGHT, GET_BODY_WIDTH } from '../graphql/layout';
import { MBCompositeSource } from './sources/MBCompositeSource';
import { MgrsLayer } from './layers/MgrsLayer';
import { UserMarker } from '../user/UserMarker';
import { MapScaleControl } from './controls/MapScaleControl';
import { MapNavigationControl } from './controls/MapNavigationControl';
import { CustomControls } from './controls/CustomControls';
import { PoiMarkers } from '../pois/PoiMarkers';
import { UnitMarkers } from '../units/UnitMarkers';
import { MapPoint } from './MapPoint';

import { NavigationLayer } from './layers/NavigationLayer';
import { MapBottomMenu } from './MapBottomMenu';

import { UserPerimeter } from './UserPerimeter';
import { MapStyleContext } from './MapStyleProvider';
import { ActiveUnitEventData, EventArea, GET_ACTIVE_UNIT_EVENT_QUERY } from '../graphql/events';
import { EventAreaLayers } from './EventAreaLayers';
import { GeolocationPosition, UserPositionContext, UserPositionMode } from '../user/UserPositionProvider';

export interface FlyToTarget {
  lng: number,
  lat: number,
  zoom?: number
  duration?: number
}

export interface MapChildProps {
  flyToCallback: (t: FlyToTarget) => void
}

export const MapPage = React.memo(() => {
  const mapRef = useRef<MapRef>(null)
  const { t } = useTranslation();
  const history = useHistory();
  const bodyHeightData = useQuery(GET_BODY_HEIGHT).data;
  const bodyWidthData = useQuery(GET_BODY_WIDTH).data;
  const { setUserPositionTrackCallback, setPositionMode, positionMode } = useContext(UserPositionContext)
  const [area, setArea] = useState<EventArea | null>(null);

  const getActiveUnitEventOnCompleted = (data: ActiveUnitEventData) => {
    if(data.getActiveUnitEvent.area) {
      setArea(JSON.parse(data.getActiveUnitEvent.area))
    }
  }

  const { error: getActiveUnitEventError } = useQuery<ActiveUnitEventData>(GET_ACTIVE_UNIT_EVENT_QUERY, {
    pollInterval: 60000,
    onCompleted: getActiveUnitEventOnCompleted
  });
  const { selectedStyleOption } = useContext(MapStyleContext)

  if(getActiveUnitEventError) {
    console.log(getActiveUnitEventError)
  }

  useEffect(() => {
    if(mapRef) {
      mapRef.current?.resize()
    }
  },[mapRef, bodyHeightData, bodyWidthData])

  //screen and (min-width: 768px) and (min-height: 768px)
  const isDesktop = bodyWidthData.bodyWidth > 768 && bodyHeightData.bodyHeight > 768;

  const [viewState, setViewState] = React.useState<ViewState>({
    latitude: 61.9241,
    longitude: 25.7482,
    zoom: 6,
    bearing: 0,
    pitch: 0,
    padding: { left: 0, top: 0, right: 0, bottom: 0}
  });

  const viewPortStyle = {
    ...viewState,
    width: isDesktop ? bodyWidthData.bodyWidth - 300 : bodyWidthData.bodyWidth,
    height: bodyHeightData.bodyHeight - 16*4,
    cursor: 'default',
    maxZoom: 19
  }

  const mapOnClick = useCallback((pointerEvent: MapLayerMouseEvent) => {
    mapRef.current?.flyTo({center: [pointerEvent.lngLat.lng, pointerEvent.lngLat.lat], duration: 500, zoom: 15});
    history.push(`/point/${pointerEvent.lngLat.lng}/${pointerEvent.lngLat.lat}`)
  }, [mapRef, history])

  const mapOnDragEnd = useCallback(() => {
    if(positionMode !== UserPositionMode.DIRTY) {
      setPositionMode(UserPositionMode.DIRTY)
    }
  }, [setPositionMode, positionMode])

  const flyToCallback = useCallback((target: FlyToTarget) => {
    mapRef.current?.flyTo({center: [target.lng, target.lat], duration: target.duration || 500, zoom: target.zoom || 15});
  }, [mapRef])

  const mapOnLoad = useCallback(() => {
    if(area) {
      flyToCallback({
        lng: area.centerLonLat[0],
        lat: area.centerLonLat[1],
        zoom: 12
      })
    }
    setUserPositionTrackCallback(prev => {
      return (position: GeolocationPosition) => {
        flyToCallback({
          lng: position.coords.longitude,
          lat: position.coords.latitude,
          zoom: 15
        })
      }
    })
  }, [area, flyToCallback, setUserPositionTrackCallback])

  return (
    <PageContent noPadding loading={false} heading={t('loading')}>
      <CustomControls flyToCallback={flyToCallback} />
      <ReactMapGL
        ref={mapRef}
        {...viewPortStyle}
        //@ts-ignore
        mapStyle={selectedStyleOption.style}
        onMove={evt => setViewState(evt.viewState)}
        onDragEnd={mapOnDragEnd}
        onClick={evt => mapOnClick(evt)}
        onLoad={mapOnLoad}
      >
        <MapScaleControl />
        <MapNavigationControl />
        {area && (
          <EventAreaLayers area={area} />
        )}
        <NavigationLayer />
        { selectedStyleOption.id !== 'satellite' && (
          <MBCompositeSource>
            <MgrsLayer />
          </MBCompositeSource>
        )}
        <UserMarker />
        <UserPerimeter minZoom={15.5} maxZoom={20} radiusMeters={50} />
        <UserPerimeter minZoom={14} maxZoom={19} radiusMeters={100} />
        <UserPerimeter minZoom={13.5} maxZoom={19} radiusMeters={200} />
        <UserPerimeter minZoom={12.5} maxZoom={19} radiusMeters={500} />
        <PoiMarkers />
        <UnitMarkers />
        <Route path="/point/:lon/:lat">
          <MapPoint />
        </Route>
      </ReactMapGL>
      <MapBottomMenu flyToCallback={flyToCallback} />
    </PageContent>
  )
})
