import React from 'react';
import styled from 'styled-components';
import { IconButton } from './IconButton';
import {CloseIcon} from './icons';
import { Keyframe } from '../utils/animations';

interface BottomMenuStyledProps {
  readonly open: boolean
  maxHeight: number
};

interface BottomMenuProps {
  readonly open: boolean
  children: any
  closeHandler: any
  refNode: any
  maxHeight: number
};

const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  left: ${props => props.theme.spacing.margin}px;
`;

export const ActionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`

const BottomMenuStyled = styled.div<BottomMenuStyledProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.dark};
  transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(100%)'};
  animation: ${Keyframe.fadeIn} 0.3s ease;
  transition: all 0.2s linear;
  z-index: 30;
  overflow-y: auto;
  width: 100vw;
  max-height: ${props => props.maxHeight}px;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: calc(100% - 300px);
    left: 300px;
  }
  
  h3 {
    margin: ${props => props.theme.spacing.margin}px 0;
  }
`;

export const BottomMenu = (props: BottomMenuProps) => {
  return (
    <BottomMenuStyled ref={props.refNode} open={props.open} maxHeight={props.maxHeight}>
      <CloseButtonContainer>
        <IconButton size={'lg'} clickHandler={props.closeHandler}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
      {props.children}
    </BottomMenuStyled>
  )
}
