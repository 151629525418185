import React, { useContext, useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import {useQuery} from '@apollo/client';
import {GET_NAVIGATION_TARGET_QUERY} from '../../graphql/pois';
import { UserPositionContext } from '../../user/UserPositionProvider';

export const NavigationLayer = React.memo(() => {
  const navigationTargetData = useQuery(GET_NAVIGATION_TARGET_QUERY).data;
  const { position } = useContext(UserPositionContext)
  const [sourceData, setSourceData] = useState<any>()
  const target = navigationTargetData?.navigationTarget;

  useEffect(() => {
    if(position && target) {
      setSourceData({
        type: 'Feature',
        properties: {},
        geometry: {
          coordinates: [
            [position.coords.longitude, position.coords.latitude],
            [target.point.coordinates[0], target.point.coordinates[1]]
          ],
          type: 'LineString'
        }
      })
    }
  }, [position, target])

  return sourceData ? (
    <Source id="navigation-line" type="geojson" data={sourceData}>
      <Layer
        id={'navigation'}
        type={'line'}
        paint={{"line-width": 2, "line-color": "hsl(223, 67%, 59%)"}}
      />
    </Source>
  ) : null
})
