import React from 'react';
import { ScaleControl } from 'react-map-gl';
import styled from 'styled-components';

const MapScaleControlStyled = styled.div`
  position: absolute;
  bottom: ${props => props.theme.spacing.margin/2}px;
  right: ${props => props.theme.spacing.margin/2}px;
  z-index: 2;
`;

export const MapScaleControl = React.memo(() => {

  return  (
    <MapScaleControlStyled>
      <ScaleControl maxWidth={150} position={'bottom-right'}/>
    </MapScaleControlStyled>
  );
})
