import React from 'react';
import {Route} from 'react-router-dom';
import {NewMarkerPage} from './NewMarkerPage';
import {EditMarkerPage} from './EditMarkerPage';
import {DeleteMarkerPage} from './DeleteMarkerPage';

export const Markers = () => {

  return (
    <>
      <Route path="/markers/create/:lon/:lat">
        <NewMarkerPage />
      </Route>
      <Route path="/markers/edit/:poiId">
        <EditMarkerPage />
      </Route>
      <Route path="/markers/delete/:poiId">
        <DeleteMarkerPage />
      </Route>
    </>
  )
}
