import React from 'react';
import {Route} from 'react-router-dom';
import {NewObservationPage} from './NewObservationPage';
import {EditObservationPage} from './EditObservationPage';
import {DeleteObservationPage} from './DeleteObservationPage';

export const Observations = () => {

  return (
    <>
      <Route path="/observations/create/:lon/:lat">
        <NewObservationPage />
      </Route>
      <Route path="/observations/edit/:poiId">
        <EditObservationPage />
      </Route>
      <Route path="/observations/delete/:poiId">
        <DeleteObservationPage />
      </Route>
    </>
  )
}
