import React from 'react';
import styled from 'styled-components';
import {UserPositionButton} from './UserPositionButton';
import {MapStyleSelector} from './MapStyleSelector';
import { MapChildProps } from '../MapPage';

const MapCustomControlsStyled = styled.div`
  position: absolute;
  display: flex;
  bottom: ${props => props.theme.spacing.margin/2}px;
  left: ${props => props.theme.spacing.margin/2}px;
  padding: ${props => props.theme.spacing.padding/2}px;
  z-index: 10;
`;

const CustomControlItem = styled.div`
  background-color: ${props => props.theme.colors.background.dark};
  margin-right: ${props => props.theme.spacing.margin / 2}px;
  padding: ${props => props.theme.spacing.padding / 2}px;
`

export const CustomControls = React.memo((props: MapChildProps) => {

  return  (
    <MapCustomControlsStyled>
      <CustomControlItem>
        <UserPositionButton {...props} />
      </CustomControlItem>
      <CustomControlItem>
        <MapStyleSelector />
      </CustomControlItem>
    </MapCustomControlsStyled>
  );
})
