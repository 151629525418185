import { DefaultTheme } from 'styled-components'

const defaultSpacing = 16;

const commandTrackerDarkTheme: DefaultTheme = {
  breakpoints: {
    mobile: `screen and (min-width: 0) and (max-width: 767px) and (orientation: portrait)`,
    mobileLandscape: `screen and (min-height: 0) and (max-height: 767px) and (orientation: landscape)`,
    desktop: `screen and (min-width: 768px) and (min-height: 768px)`
  },
  spacing: {
    default: defaultSpacing,
    lineHeight: 1.5,
    padding: defaultSpacing,
    margin: defaultSpacing,
    borderRadius: defaultSpacing / 4,
  },
  colors: {
    background: {
      dark: '#222222',
      default: '#3e3e3e',
      light: '#737272',
    },
    main: '#ffffff',
    dark: '#222222',
    secondary: '#c95200',
    tertiary: '#1a7392',
    user: '#004dff',
    marker: '#ff0000'
  },
  font: {
    family: {
      main: '\'Montserrat\', sans-serif',
      secondary: '\'Roboto\', sans-serif'
    },
    size: {
      default: '14pt',
      small: '12pt',
      large: '16pt'
    }
  }
}

export { commandTrackerDarkTheme }
