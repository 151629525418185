import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';

import {GET_ACTIVE_UNIT_QUERY, GetActiveUnitData, JOIN_UNIT_MUTATION} from '../graphql/units';
import {FullPage} from '../components/FullPage';
import {useForm} from 'react-hook-form';
import {Label} from '../components/Label';
import styled from 'styled-components';
import {ErrorMessage} from '../components/ErrorMessage';

type Inputs = {
  joinSecret: string
};

const Description = styled.p`
  margin: ${props => props.theme.spacing.margin}px;
`;

export const JoinPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading: getActiveUnitLoading, refetch } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);
  const [joinUnit, { loading: joinLoading, error: joinUnitError }] = useMutation(JOIN_UNIT_MUTATION);
  const { register, handleSubmit, errors, formState } = useForm<Inputs>({ mode: 'onChange'});

  const onSubmit = (formData) => {
    const joinUnitVariables = {
      joinSecret: formData.joinSecret
    }

    joinUnit({
      variables: joinUnitVariables,
    })
      .then(() => refetch())
      .then(() => {
        history.push(`/`)
      })
      .catch(e => {
        console.log(e)
      });
  };

  return (
    <FullPage
      heading={t('join.pageHeader')}
      loading={getActiveUnitLoading || joinLoading}
      closeHandler={() => history.replace(`/`)}
    >
      <Description>{t('join.description')}</Description>
      {joinUnitError && <ErrorMessage message={t('join.error')} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label htmlFor={'joinSecret'} hasError={errors.joinSecret ? 'true' : 'false'}>
          {t('units.joinSecret')}{errors.joinSecret && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="joinSecret" name="joinSecret" placeholder={t('units.joinSecret')} />
        <input disabled={!formState.isValid} type="submit" value={t('join.join') as string}/>
      </form>


    </FullPage>
  )
}
