import React from 'react';
import ReactDOM from 'react-dom';
import 'dayjs/locale/fi'
import dayjs from 'dayjs';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ThemeProvider } from 'styled-components';
import { commandTrackerDarkTheme } from './theme';

import './i18n'
// TODO: Repace with detected
dayjs.locale('fi');

ReactDOM.render((
  <ThemeProvider theme={commandTrackerDarkTheme}>
    <App />
  </ThemeProvider>),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
