import React, { Suspense } from 'react';
import Amplify from 'aws-amplify';
import { signUpConfig, usernameAttributes } from './common/aws/cognito.config';
import awsconfig from './common/aws/aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import { client } from './common/aws/appsync-client';
import { ApolloProvider } from '@apollo/client';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import styled from 'styled-components';

import { MainHeading } from './layout/MainHeading';
import { MainMenu } from './layout/MainMenu';

import '@aws-amplify/ui/dist/style.css';
import './App.css';
import { GlobalStyle } from './GlobalStyle';
import {Keyframe} from './utils/animations';

import {GlobalLoadingContainer} from './layout/GlobalLoadingContainer';
import {JoinPage} from './join/JoinPage';
import {MapPage} from './map/MapPage';
import {Observations} from './observations/Observations';
import {Markers} from './markers/Markers';
import {OrbatPage} from './orbat/OrbatPage';
import { UserPositionProvider } from './user/UserPositionProvider';
import { MapStyleProvider } from './map/MapStyleProvider';

Amplify.configure(awsconfig)

export const MainContainer = styled.div`
  color: ${props => props.theme.colors.main};
  display: flex;
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  animation: ${Keyframe.fadeIn} 0.3s ease;
`;

export interface PageProps {
  loading: boolean
}

function App() {
  return (
    //@ts-ignore
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <MainContainer id={'main-container'}>
            <Suspense fallback={'Loading ...'}>
              <GlobalStyle />
              <UserPositionProvider>
                <MainHeading />
                <MainMenu />
                <Switch>
                  <Route path="/join">
                    <JoinPage />
                  </Route>
                  <GlobalLoadingContainer>

                      <MapStyleProvider>
                        <Route path="/">
                          <MapPage />
                        </Route>
                        <Route path={['/orbat/view/:unitId', '/orbat']}>
                          <OrbatPage />
                        </Route>
                        <Route path="/observations">
                          <Observations />
                        </Route>
                        <Route path="/markers">
                          <Markers />
                        </Route>
                        <Route path="/unauthorized">
                          <h1>Unauthorized</h1>
                        </Route>
                      </MapStyleProvider>

                  </GlobalLoadingContainer>
                </Switch>
              </UserPositionProvider>
            </Suspense>
          </MainContainer>
        </div>

      </Router>
    </ApolloProvider>
  );
}

//@ts-ignore
export default withAuthenticator(App, {
  signUpConfig,
  usernameAttributes
});