import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {Marker} from 'react-map-gl';
import {sidcToUnitMapMilSymbol} from '../utils/symbols';
import styled from 'styled-components';
import {LimitedUnit} from '../graphql/units';
import {
  DEVICE_POSITIONS_SUBSCRIPTION,
  GET_DEVICE_POSITIONS,
  GetDevicePositionsData, newDevicePositionsHandler
} from '../graphql/devices';
import {useQuery} from '@apollo/client';

interface UnitMarkerProps {
  unit: LimitedUnit
  parent?: LimitedUnit
}

const UnitSymbol = styled.img`
  
`;

export const UnitMarker = React.memo((props: UnitMarkerProps) => {
  const history = useHistory();
  const { unit } = props;
  const deviceId = unit.device?.id;
  const { subscribeToMore, data } = useQuery<GetDevicePositionsData>(GET_DEVICE_POSITIONS,
    {
      variables: { deviceId }
    });

  const clickHandler = event => {
    history.push(`/units/view/${unit.id}`)
    event.preventDefault()
    event.stopPropagation()
  }

  useEffect(() => {
    let unsub;
    const errorHandler = error => {
      console.log('reconnecting', error);
      unsub = subscribeToMore({
        document: DEVICE_POSITIONS_SUBSCRIPTION,
        variables: { deviceId },
        updateQuery: newDevicePositionsHandler,
        onError: errorHandler
      })
    }
    unsub = subscribeToMore({
      document: DEVICE_POSITIONS_SUBSCRIPTION,
      variables: { deviceId },
      updateQuery: newDevicePositionsHandler,
      onError: errorHandler
    })
    return function cleanup() { unsub(); }
  }, [deviceId, subscribeToMore]);

  const milsymbol = sidcToUnitMapMilSymbol(props.unit.sidc, props.unit.name, props.unit.additionalInfo, props.parent?.name);
  const svg = milsymbol.asSVG();
  const anchor = milsymbol.getAnchor();

  const devicePosition = data?.getDevicePositions[0];

  return  devicePosition ? (
    <Marker
      latitude={devicePosition.latitude}
      longitude={devicePosition.longitude}
      offset={[-anchor.x,-anchor.y]}
      anchor={'top-left'}
    >
      <UnitSymbol src={`data:image/svg+xml;utf8,${svg}`} onClick={clickHandler} />
    </Marker>

  ) : null;
})
