import React from 'react';
import styled from 'styled-components';

interface IconButtonProps {
  clickHandler: any
  children: any
  size: 'lg' | 'sm'
};

interface ButtonStyledProps {
  size: 'lg' | 'sm'
};

const ButtonStyled = styled.button<ButtonStyledProps>`
  user-select: none;
  outline: none;
  background: none;
  padding: ${props => props.theme.spacing.padding / 2}px ${props => props.theme.spacing.padding}px;
  color: ${props => props.theme.colors.main};
  font-size: ${props => props.theme.font.size.large};
  cursor: pointer;
  display: inline-block;
  border: ${props => props.theme.colors.main} 1px solid;
  border-radius: ${props => props.theme.spacing.borderRadius}px;
  
  &:hover {
    border: ${props => props.theme.colors.background.light} 1px solid;
  }
`;

export const Button = (props: IconButtonProps) => {
  return (
    <ButtonStyled size={props.size} onClick={props.clickHandler}>
      {props.children}
    </ButtonStyled>
  )
}
