import styled from 'styled-components';
import React from 'react';
import {ErrorIcon} from './icons';

interface ErrorMessageProps {
  message: string
}

export const ErrorMessageStyled = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  svg {
    margin-right: ${props => props.theme.spacing.margin}px;
    color: ${props => props.theme.colors.main};
  }
`;

export const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <ErrorMessageStyled>
      <ErrorIcon />{props.message}
    </ErrorMessageStyled>
  )
}
