import React, { useEffect, useState } from 'react';


export enum UserPositionMode {
  DIRTY='dirty', USER_POSITION='userPosition', TRACK_USER='trackUser'
}

interface GeolocationCoordinates {
  accuracy: number
  latitude: number
  longitude: number
}

export interface GeolocationPosition {
  coords: GeolocationCoordinates
  timestamp: number
}

export interface IUserPositionContext {
  position: GeolocationPosition | null,
  positionMode: UserPositionMode
  setPositionMode: (mode: UserPositionMode) => void
  setUserPositionTrackCallback: (callback: (position: GeolocationPosition) => void) => void
}

export const UserPositionContext = React.createContext<IUserPositionContext>({
  position: null,
  positionMode: UserPositionMode.DIRTY,
  setPositionMode: () => {},
  setUserPositionTrackCallback: () => {}
});

export const UserPositionProvider = (props: any) => {

  const [userPosition, setUserPosition] = useState<GeolocationPosition | null>(null);
  const [positionMode, setPositionMode] = useState<UserPositionMode>(UserPositionMode.DIRTY);
  const [userPositionTrackCallback, setUserPositionTrackCallback] = useState<((position: GeolocationPosition) => void) | null>(null);

  const handleNewPosition = (position: GeolocationPosition, mode: UserPositionMode, trackCallback?: ((position: GeolocationPosition) => void) | null) => {
    setUserPosition(position);
    if(mode === UserPositionMode.TRACK_USER && trackCallback) {
      trackCallback(position)
    }
  }

  const handlePositionError = error => {
    console.log(error)
  }

  useEffect(() => {
    let watchId = 0;

    if (navigator.geolocation) {
      watchId = navigator.geolocation.watchPosition(
        (position: GeolocationPosition) => handleNewPosition(position, positionMode, userPositionTrackCallback),
        handlePositionError,
        { enableHighAccuracy: true }
      );
    }

    return () => {
      if (navigator.geolocation) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [positionMode, userPositionTrackCallback]);

  return (
    //@ts-ignore
    <UserPositionContext.Provider value={{
      position: userPosition,
      positionMode,
      setPositionMode,
      setUserPositionTrackCallback
    }}>
      {props.children}
    </UserPositionContext.Provider>
  )
}
