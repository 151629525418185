import {InMemoryCache, makeVar} from '@apollo/client';
import {storageAvailable, get, put, StorageKey} from '../utils/local-storage';
import {Point} from 'geojson';

interface NavigationTarget {
  name: string
  point: Point
}

export const menuOpenVar = makeVar<boolean>(false);
export const bodyWidthVar = makeVar<number>(0);
export const bodyHeightVar = makeVar<number>(0);
export const hiddenUnits = makeVar<string[]>([]);
export const navigationTargetVar = makeVar<NavigationTarget | undefined>(undefined);

if(storageAvailable) {
  const storageHiddenUnits = get(StorageKey.HIDDEN_UNITS);
  if(!storageHiddenUnits) {
    put(StorageKey.HIDDEN_UNITS, JSON.stringify([]));
  } else {
    hiddenUnits(JSON.parse(storageHiddenUnits))
  }
}

export const addHiddenUnit = (unitId) => {
  const currentUnits = hiddenUnits();
  if(!currentUnits.includes(unitId)) {
    const newHiddenUnits = [...currentUnits, unitId];
    hiddenUnits(newHiddenUnits);
    if(storageAvailable) {
      put(StorageKey.HIDDEN_UNITS, JSON.stringify(newHiddenUnits));
    }
  }
}

export const removeHiddenUnit = (unitId) => {
  const currentUnits = hiddenUnits();
  if(currentUnits.includes(unitId)) {
    const newHiddenUnits = currentUnits
      .filter(id => id !== unitId);
    hiddenUnits(newHiddenUnits);
    if(storageAvailable) {
      put(StorageKey.HIDDEN_UNITS, JSON.stringify(newHiddenUnits));
    }
  }
}

export const cache = new InMemoryCache({
  typePolicies: {
    LimitedUnit: {
      fields: {
        follow: {
          read(_, { variables }) {
            return !hiddenUnits().includes(variables?.id);
          }
        }
      }
    },
    Query: {
      fields: {
        hiddenUnits: {
          read() {
            return hiddenUnits();
          }
        },
        menuOpen: {
          read() {
            return menuOpenVar();
          }
        },
        bodyWidth: {
          read() {
            return bodyWidthVar();
          }
        },
        bodyHeight: {
          read() {
            return bodyHeightVar();
          }
        },
        navigationTarget: {
          read() {
            return navigationTargetVar();
          }
        },
        getPoi(_, { args, toReference }) {
          return toReference({
            __typename: 'Poi',
            id: args?.id,
          });
        },
        getFactionUnit(_, { args, toReference }) {
          return toReference({
            __typename: 'LimitedUnit',
            id: args?.id,
          });
        },
        getPoisByVisibility: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        }
      }
    }
  }
});
