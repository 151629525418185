import React, { Fragment } from 'react';
import { Layer } from 'react-map-gl';

export const MgrsLayer = React.memo(() => {
  return (
    <Fragment>
      <Layer
        id={'mgrs100k'}
        source={'mgrs'}
        type={'line'}
        source-layer={'100kGrids-c4g2r9'}
        maxzoom={8}
        paint={{"line-color": "hsl(0,93%,70%)"}}
      />
      <Layer
        id={'mgrs10k'}
        source={'mgrs'}
        type={'line'}
        source-layer={'10kGrids-51h0gr'}
        minzoom={8}
        maxzoom={13}
        paint={{
          "line-color": [
            "step",
            ["zoom"],
            "hsl(0,93%,70%)",
            11,
            "hsl(0,93%,70%)"
          ],
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            1,
            11,
            4
          ]
        }}
      />
      <Layer
        id={'mgrs1k'}
        source={'mgrs'}
        type={'line'}
        source-layer={'1kGrids-chuvnt'}
        minzoom={11}
        paint={{"line-color": "hsl(0,93%,70%)"}}
      />

      <Layer
        id={'mgrs100k-labels'}
        source={'mgrs'}
        type={'symbol'}
        source-layer={'100kLabels-aj3zxv'}
        minzoom={5.5}
        maxzoom={8.5}
        paint={{"text-color": "#f29797"}}
        layout={{"text-field": ["to-string", ["get", "label"]], "text-font": ["Liberation Sans NLSFI"]}}
      />
    </Fragment>

  )
})
