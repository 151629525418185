import React, {Fragment, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {ActionMenuItem} from '../components/ActionMenuItem';
import {DeleteIcon, DirectionsIcon, EditIcon} from '../components/icons';
import {GET_POI_QUERY, GetPoiData, ObservationProperties} from '../graphql/pois';
import {useTranslation} from 'react-i18next';
import {getSidcAffiliation, getSidcFunctionId, getSidcUnitSize} from '../utils/symbols';
import styled from 'styled-components';
import {LatLon} from 'geodesy/mgrs';
import {Point} from 'geojson';
import dayjs from 'dayjs';
import {ActionList} from '../components/BottomMenu';
import {useQuery} from '@apollo/client';
import {navigationTargetVar} from '../graphql/cache';
import {IconButton} from '../components/IconButton';
import { MapChildProps } from '../map/MapPage';

const DataItem = styled.li`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.margin}px;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing.padding}px;
`;

const DataTable = styled.table`
  font-size: ${props => props.theme.font.size.default};
  tr {
    text-align: left;
  }
`
const TdHeading = styled.td`
  padding-right: ${props => props.theme.spacing.padding}px;
  vertical-align: top;
  text-wrap: none;
`

const NavigationButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  right: ${props => props.theme.spacing.margin}px;
`;

export const ObservationBottomMenu = (props: MapChildProps) => {
  const { t } = useTranslation();
  const { poiId } = useParams();
  const { flyToCallback } = props

  const { data: getPoiData } = useQuery<GetPoiData>(GET_POI_QUERY, {
    variables: {
      id: poiId
    }
  });

  const observation = getPoiData?.getPoi;
  const properties: ObservationProperties = observation ? JSON.parse(observation.properties) : null;
  const point: Point = observation ? JSON.parse(observation.geometry) : null;

  useEffect(() => {
    if(point) {
      flyToCallback({
        lng: point.coordinates[0],
        lat: point.coordinates[1],
        zoom: 14.5
      })
    }
  }, [point, flyToCallback])

  const affiliation = properties ? t(`units.affiliation.${getSidcAffiliation(properties.sidc)}`) : '';
  const size = properties ? t(`units.size.${getSidcUnitSize(properties.sidc)}`) : '';
  const functionId = properties ? t(`units.functionId.${getSidcFunctionId(properties.sidc)}`) : '';

  const mgrs = point ? new LatLon(point.coordinates[1], point.coordinates[0]).toUtm().toMgrs().toString() : '';

  const navigateToClickHandler = (point: Point) => {
    navigationTargetVar({
      name: affiliation || '',
      point
    });
  }

  return (
    <Fragment>
      <NavigationButtonContainer>
        <IconButton size={'lg'} clickHandler={() => navigateToClickHandler(point)}>
          <DirectionsIcon />
        </IconButton>
      </NavigationButtonContainer>
      <h3>{affiliation}</h3>
      <ActionList>
        <DataItem>
          <DataTable>
            <tbody>
            <tr>
              <TdHeading>{t('units.unitFunction')}:</TdHeading>
              <td>{functionId}</td>
            </tr>
            <tr>
              <TdHeading>{t('units.unitSize')}:</TdHeading>
              <td>{size}</td>
            </tr>
            <tr>
              <TdHeading>MGRS:</TdHeading>
              <td>{mgrs}</td>
            </tr>
            <tr>
              <TdHeading>{t('created')}:</TdHeading>
              <td>{dayjs(observation?.created).format('HH:mm DD.MM.YYYY')}</td>
            </tr>
            <tr>
              <TdHeading>{t('creator')}:</TdHeading>
              <td>{observation?.owner?.name}</td>
            </tr>
            {
              observation?.modified ? (
                <tr>
                  <TdHeading>{t('modified')}:</TdHeading>
                  <td>{dayjs(observation.modified).format('HH:mm DD.MM.YYYY')}</td>
                </tr>
              ) : null
            }
            {
              observation?.expireTime ? (
                <tr>
                  <TdHeading>{t('expires')}:</TdHeading>
                  <td>{dayjs(observation.expireTime).format('HH:mm DD.MM.YYYY')}</td>
                </tr>
              ) : null
            }
            {
              properties && properties.description !== '' && (
                <tr>
                  <TdHeading>{t('description')}:</TdHeading>
                  <td>{properties.description}</td>
                </tr>
              )
            }
            </tbody>
          </DataTable>
        </DataItem>
        <ActionMenuItem>
          <EditIcon />
          <Link to={`/observations/edit/${poiId}`}>
            {t('edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <DeleteIcon />
          <Link to={`/observations/delete/${poiId}`}>
            {t('delete')}
          </Link>
        </ActionMenuItem>
      </ActionList>
    </Fragment>
  )
}
