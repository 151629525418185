
const signUpConfig = {
    header: 'My Customized Sign Up',
    hideAllDefaults: true,
    defaultCountryCode: '1',
    signUpFields: [
        {
            label: 'Name',
            key: 'name',
            required: true,
            displayOrder: 1,
            type: 'string',
            placeholder: 'Enter name'
        },
        {
            label: 'Email',
            key: 'email',
            required: true,
            displayOrder: 2,
            type: 'string',
            placeholder: 'Enter email'
        },
        {
            label: 'Password',
            key: 'password',
            required: true,
            displayOrder: 3,
            type: 'password',
            placeholder: 'Password'
        }
    ]
};

const usernameAttributes = 'Email';

export {
    signUpConfig,
    usernameAttributes
}