import React from 'react';
import styled from 'styled-components';
import {ArrowBack} from '@styled-icons/material/ArrowBack';
import {ArrowLongLeft} from '@styled-icons/entypo/ArrowLongLeft';
import {Edit} from '@styled-icons/material/Edit';
import {Delete} from '@styled-icons/material/Delete';
import {Add} from '@styled-icons/material/Add';
import {SpeakerPhone} from '@styled-icons/material/SpeakerPhone';
import {Close} from '@styled-icons/material/Close';
import {Lock} from '@styled-icons/material/Lock';
import {Error} from '@styled-icons/material/Error';
import {PersonPinCircle} from '@styled-icons/material/PersonPinCircle';
import {TargetLock} from '@styled-icons/boxicons-regular/TargetLock';
import {Binoculars} from '@styled-icons/fa-solid/Binoculars';
import {PushPin} from '@styled-icons/material/PushPin';
import {Circle} from '@styled-icons/boxicons-regular/Circle';
import {SkullCrossbones} from '@styled-icons/fa-solid/SkullCrossbones';
import {ExclamationTriangle} from '@styled-icons/bootstrap/ExclamationTriangle';
import {Layers} from '@styled-icons/material/Layers';
import {MapMarkedAlt} from '@styled-icons/fa-solid/MapMarkedAlt';
import {Person} from '@styled-icons/material/Person';
import {BatteryStd} from '@styled-icons/material/BatteryStd';
import {Directions} from '@styled-icons/material/Directions';
import {DotSingle} from '@styled-icons/entypo/DotSingle';
import {LocalHospital} from '@styled-icons/material-sharp/LocalHospital';
import {Square} from '@styled-icons/ionicons-outline/Square';
import {Tag} from '@styled-icons/boxicons-regular/Tag';
import {Tag as TagSolid} from '@styled-icons/boxicons-solid/Tag';
import {TriangleDown} from '@styled-icons/entypo/TriangleDown';
import {Crosshairs} from '@styled-icons/fa-solid/Crosshairs';
import {Monument} from '@styled-icons/fa-solid/Monument';


export const BackArrowIcon = styled(ArrowBack)`
`

export const ArrowLongLeftIcon = styled(ArrowLongLeft)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DotSingleIcon = styled(DotSingle)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const LocalHospitalIcon = styled(LocalHospital)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const SquareIcon = styled(Square)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const TagIcon = styled(Tag)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const TagSolidIcon = styled(TagSolid)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const TriangleDownIcon = styled(TriangleDown)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const CrosshairIcon = styled(Crosshairs)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const MonumentIcon = styled(Monument)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const EditIcon = styled(Edit)`
  height: ${props => props.theme.spacing.default*1.5}px;
  width: ${props => props.theme.spacing.default*1.5}px;
`

export const DeleteIcon = styled(Delete)`
  height: ${props => props.theme.spacing.default*1.5}px;
  width: ${props => props.theme.spacing.default*1.5}px;
`

export const AddIcon = styled(Add)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DeviceIcon = styled(SpeakerPhone)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const CloseIcon = styled(Close)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const LockIcon = styled(Lock)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const ErrorIcon = styled(Error)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const PersonPinIcon = styled(PersonPinCircle)`
  height: ${props => props.theme.spacing.default*3}px;
  width: ${props => props.theme.spacing.default*3}px;
`

export const SmallPersonPinIcon = styled(PersonPinCircle)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const TargetLockIcon = styled(TargetLock)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const BinocularsIcon = styled(Binoculars)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const PushPinIcon = styled(PushPin)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const CircleIcon = styled(Circle)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const SkullCrossboneseIcon = styled(SkullCrossbones)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const ExclamationTriangleIcon = styled(ExclamationTriangle)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const LayersIcon = styled(Layers)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const MapMarkedAltIcon = styled(MapMarkedAlt)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const PersonIcon = styled(Person)`
  height: ${props => props.theme.spacing.default*3}px;
  width: ${props => props.theme.spacing.default*3}px;
`

export const BatteryStdIcon = styled(BatteryStd)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DirectionsIcon = styled(Directions)`
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const markerIconOptions = [
  { id: 'cross', icon: (<AddIcon />) },
  { id: 'circle', icon: (<CircleIcon />) },
  { id: 'arrow', icon: (<ArrowLongLeftIcon />) },
  { id: 'skullCrossbones', icon: (<SkullCrossboneseIcon />) },
  { id: 'exclamationTriangle', icon: (<ExclamationTriangleIcon />) },
  { id: 'dot', icon: (<DotSingleIcon />) },
  { id: 'hospital', icon: (<LocalHospitalIcon />) },
  { id: 'square', icon: (<SquareIcon />) },
  { id: 'tag', icon: (<TagIcon />) },
  { id: 'tagSolid', icon: (<TagSolidIcon />) },
  { id: 'triangle', icon: (<TriangleDownIcon />) },
  { id: 'crosshair', icon: (<CrosshairIcon />) },
  { id: 'monument', icon: (<MonumentIcon />) },
]

export const markerIconColorOptions = [
  { id: 'red', hex: '#ff0000' },
  { id: 'blue', hex: '#004dff' },
  { id: 'green', hex: '#027102' },
  { id: 'yellow', hex: '#c7c700' },
  { id: 'orange', hex: '#ff8400' },
]
