
enum StorageKey {
  HIDDEN_UNITS= 'HIDDEN_UNITS'
}

const storageAvailable = typeof(Storage) !== "undefined";

const put = (key, value) => {
  if(storageAvailable) {
    localStorage.setItem(key, value);
  }
}

const get = (key) => {
  if(storageAvailable) {
    return localStorage.getItem(key);
  }
}

const remove = (key) => {
  if(storageAvailable) {
    localStorage.removeItem(key);
  }
}

export {
  storageAvailable,
  StorageKey,
  put,
  get,
  remove
}