import React from 'react';
import { NavigationControl } from 'react-map-gl';
import styled from 'styled-components';
import {useQuery} from '@apollo/client';
import {GET_BODY_HEIGHT, GET_BODY_WIDTH} from '../../graphql/layout';

const MapNavigationControlStyled = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin/2}px;
  right: ${props => props.theme.spacing.margin/2}px;
  z-index: 2;
`;

export const MapNavigationControl = React.memo(() => {
  const { bodyHeight }  = useQuery(GET_BODY_HEIGHT).data;
  const { bodyWidth } = useQuery(GET_BODY_WIDTH).data;
  const isLandscape =  bodyWidth > bodyHeight;
  return  isLandscape ? (
    <MapNavigationControlStyled>
      <NavigationControl />
    </MapNavigationControlStyled>
  ) : null;
})
