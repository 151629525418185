import React from 'react';
import styled from 'styled-components';
import {LimitedUnit} from '../graphql/units';
import {sidcToSymbolOrbat} from '../utils/symbols';
import {DeviceIcon} from './icons';

interface OrbatListItemProps {
  unit: LimitedUnit
  clickHandler: any
};

const UnitSymbol = styled.img`
  height: 2em;
  margin-right: 0.5em;
  vertical-align: text-bottom;
`;

const Children = styled.ul`
`;
const OrbatListItemStyled = styled.li`
  svg {
    height: ${props => props.theme.spacing.lineHeight}em;
    width: ${props => props.theme.spacing.lineHeight}em;
    margin-bottom: ${props => props.theme.spacing.margin/2}px;
  }
`;

export const OrbatListItem = (props: OrbatListItemProps) => {
  const { sidc, name, additionalInfo, device, children, follow } = props.unit;

  return (
    <OrbatListItemStyled>
      <UnitSymbol onClick={() => props.clickHandler(props.unit)} src={`data:image/svg+xml;utf8,${sidcToSymbolOrbat(sidc, follow)}`} />
        { device && (<DeviceIcon />)}
      <span onClick={() => props.clickHandler(props.unit)}>
        {name}, {additionalInfo}
      </span>
      { children && (
        <Children>
          { children.map(child => <OrbatListItem clickHandler={props.clickHandler} key={child.id} unit={child}/> )}
        </Children>
      )}
    </OrbatListItemStyled>
  )
}
