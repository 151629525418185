import React from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { SideMenu } from '../components/SideMenu';
import {useQuery} from '@apollo/client';
import {GET_BODY_HEIGHT, GET_MENU_OPEN} from '../graphql/layout';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {menuOpenVar} from '../graphql/cache';
import {BatteryStdIcon, DeviceIcon, PersonIcon} from '../components/icons';
import {GET_USER_QUERY, GetUserData} from '../graphql/users';
import {GET_ACTIVE_UNIT_QUERY, GetActiveUnitData} from '../graphql/units';
import {
  DevicePositionAttributes,
  GET_DEVICE_POSITIONS,
  GetDevicePositionsData
} from '../graphql/devices';

const MenuItemList = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const MenuListItem = styled.li`
  width: 100%;
  margin: ${props => props.theme.spacing.margin}px 0;
  font-size: ${props => props.theme.font.size.large};
  cursor: pointer;
`

const UserMenuListItem = styled.li`
  width: 100%;
  display: flex;
  margin-top: ${props => props.theme.spacing.margin}px;
  margin-bottom: ${props => props.theme.spacing.margin*2}px;
  justify-content: center;
  h3 {
    margin: 0;
  }
`

const DeviceMenuListItem = styled.li`
  width: 100%;
  display: flex;
  margin-top: ${props => props.theme.spacing.margin}px;
  margin-bottom: ${props => props.theme.spacing.margin}px;
  justify-content: center;
  h3 {
    margin: 0;
  }
`

export const MainMenu = () => {
  const menuData = useQuery(GET_MENU_OPEN).data;
  const bodyData = useQuery(GET_BODY_HEIGHT).data;
  const { data: getUserData } = useQuery<GetUserData>(GET_USER_QUERY);
  const { data: getActiveUnitData } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);

  const unit = getActiveUnitData?.getActiveUnit;
  const device = unit?.device;
  const user = getUserData?.getUser;

  const { data: devicePositionData } = useQuery<GetDevicePositionsData>(GET_DEVICE_POSITIONS,
    {
      variables: { deviceId: device?.id }
    });
  const attributesJson = devicePositionData?.getDevicePositions[0]?.attributes
  const attributes: DevicePositionAttributes = attributesJson ? JSON.parse(attributesJson) : undefined;

  const { t } = useTranslation();
  const history = useHistory();

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <SideMenu open={menuData.menuOpen} bodyHeight={bodyData.bodyHeight}>
      <MenuItemList>
        <UserMenuListItem>

            <PersonIcon />

          <h3>{user?.name}<br />
            {unit?.name}, {unit?.additionalInfo}
          </h3>

        </UserMenuListItem>
        <MenuListItem onClick={() => {
          menuOpenVar(false);
          history.push('/orbat');
        }}>
          {t('orbat')}
        </MenuListItem>
        <MenuListItem onClick={() => {
          menuOpenVar(false);
          history.push('/join');
        }}>
          {t('join.join')}
        </MenuListItem>
      </MenuItemList>
      <MenuItemList>
        <DeviceMenuListItem>
            <DeviceIcon /><h3>{device ?  device.name : t('noTracker')}</h3>
        </DeviceMenuListItem>
        <DeviceMenuListItem>
          <BatteryStdIcon /><h3>{attributes ?  attributes.batteryLevel : 'n/a'}%</h3>
        </DeviceMenuListItem>

        <MenuListItem onClick={signOut}>
          {t('logout')}
        </MenuListItem>
      </MenuItemList>
    </SideMenu>
  )
}
