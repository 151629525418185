import { gql } from '@apollo/client';
import {LimitedUser} from './users';
import {Device} from './devices';

export interface Visibility {
  id: number,
  visibility: string
}

export interface Unit {
  id: number
  parentId: number
  visibilities: Visibility[]
  children?: Unit[]
  user: LimitedUser
  sidc: string
  name: string
  additionalInfo: string
  joinSecret: string
  device?: Device
}

export interface LimitedUnit {
  id: number
  parentId: number
  user: LimitedUser
  children?: LimitedUnit[]
  sidc: string
  name: string
  additionalInfo: string
  device?: Device
  follow: boolean
}

export interface CreateUnit {
  eventId: number
  factionId: number
  parentId: number
  sidc: string
  name: string
  additionalInfo: string
}

export interface DeleteUnit {
  eventId: number
  factionId: number
  id: number
}

export interface UpdateUnit {
  id: number
  eventId: number
  factionId: number
  parentId: number
  sidc: string
  name: string
  additionalInfo: string
}

export interface AdminUnitsData {
  adminGetUnits: Unit[]
}

export interface GetFactionUnitsData {
  getFactionUnits: LimitedUnit[]
}

export interface GetFactionUnitData {
  getFactionUnit: LimitedUnit
}

export interface GetActiveUnitData {
  getActiveUnit: Unit
}

export interface AdminUnitData {
  adminGetUnit: Unit
}

const UnitQuery =`
  id
  parentId
  visibilities {
    id
    visibility
  }
  user {
    id
    name
  }
  device {
    id
    name
    lastUpdate
  }
  sidc
  name
  additionalInfo
  joinSecret
`

const LimitedUnitQuery =`
  id
  parentId
  user {
    id
    name
  }
  device {
    id
    name
    lastUpdate
  }
  sidc
  name
  additionalInfo
  follow @client
`

const GET_HIDDEN_UNITS = gql`
  query GetHiddenUnits {
    hiddenUnits @client
  }
`;

const CREATE_SUB_UNIT_MUTATION = gql`
  mutation CreateSubUnit($parentId: Int!, $sidc: String!, $name: String!, $additionalInfo: String) {
    createSubUnit(parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}   
    }
  } 
  `;

const UPDATE_SUB_UNIT_MUTATION = gql`
  mutation UpdateSubUnit($id: ID!, $parentId: Int!, $sidc: String!, $name: String!, $additionalInfo: String) {
    updateSubUnit(id: $id, parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}
    }
  } 
  `;

const DELETE_SUB_UNIT_MUTATION = gql`
  mutation DeleteSubUnit($id: ID!) {
    deleteSubUnit(id: $id) { 
      ${UnitQuery}
    }
  } 
  `;

const GET_ACTIVE_UNIT_QUERY = gql`
  query GetActiveUnit {
      getActiveUnit { 
        ${UnitQuery}
      }
    } 
  `;

const JOIN_UNIT_MUTATION = gql`
  mutation JoinUnit($joinSecret: String!) {
    joinUnit(joinSecret: $joinSecret) {
      ${UnitQuery}
    }
  } 
  `;

const GET_FACTION_UNITS_QUERY = gql`
  query GetFactionUnits {
    getFactionUnits {
      ${LimitedUnitQuery}
    }
  } 
  `;

const GET_FACTION_UNIT_QUERY = gql`
  query GetFactionUnit($id: ID!) {
    getFactionUnit(id: $id) {
      ${LimitedUnitQuery}
    }
  } 
  `;

export interface ParentOption {
  id: number
  name: string
}

const sortByNameAsc = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const joinUnitUpdateCache = (client, result) => {
  try {
    const { joinUnit } = result.data;

    const newData = {
      getActiveUnit: joinUnit
    }
    client.writeQuery({
      query: GET_ACTIVE_UNIT_QUERY,
      data: newData
    });
  } catch(e) {
    // DO nothing active unit might not exist
    console.log(e);
  }

}

const setUnitChildren = (unit: Unit, allUnits: Unit[]): Unit => {
  return {
    ...unit,
    children: allUnits
      .filter(u => u.parentId === parseInt(unit.id+''))
      .map(unit => setUnitChildren(unit, allUnits))
  }
}

const setLimitedUnitChildren = (unit: LimitedUnit, allUnits: LimitedUnit[], hiddenUnits?: string[]): LimitedUnit => {
  return {
    ...unit,
    follow: hiddenUnits && unit.device? !hiddenUnits?.includes(`${unit.id}`) : false,
    children: allUnits
      .filter(u => u.parentId === parseInt(unit.id+''))
      .map(unit => setLimitedUnitChildren(unit, allUnits, hiddenUnits))
  }
}

const unitsToTree = (units: Unit[]): Unit[] => {
  return units
    .filter(unit => !unit.parentId)
    .map(rootUnit => setUnitChildren(rootUnit, units));
}

const limitedUnitsToTree = (units: LimitedUnit[], hiddenUnits?: string[]): LimitedUnit[] => {
  return units
    .filter(unit => !unit.parentId)
    .map(rootUnit => setLimitedUnitChildren(rootUnit, units, hiddenUnits));
}

const isChild = (parent: Unit, unit: Unit) => {
  return parent.children?.find(child => child.id === unit.id || isChild(child, unit))
}

export interface VisibilityOption {
  id: number
  visibility: string
  name: string
}

const limitedUnitToVisibilityOptions = (prefix: string, visibilityPrefix: string, unit: LimitedUnit) : VisibilityOption[] => {
  const name = `${prefix}${unit.name}`
  const visibility = `${visibilityPrefix}-${unit.id}`;
  const option: VisibilityOption = {
    id: unit.id,
    visibility,
    name
  }
  const childOptions = unit.children?.map(child => limitedUnitToVisibilityOptions(`${name} > `, `${visibility}`, child))
    .reduce((prev, cur) => {
      return prev.concat(cur)
    }, []) || [];
  return [option, ...childOptions];
}

const getVisibilityOptionsFromVisibilityString = (visibility: string): string[] => {
  const parts = visibility.split('-');
  let prefix = `${parts[0]}-${parts[1]}`;
  const options = [prefix];
  parts
    .slice(2)
    .forEach(part => {
      prefix = `${prefix}-${part}`;
      options.push(`${prefix}`)
    });
  return options;
}

export {
  GET_ACTIVE_UNIT_QUERY,
  JOIN_UNIT_MUTATION,
  GET_FACTION_UNITS_QUERY,
  GET_FACTION_UNIT_QUERY,
  CREATE_SUB_UNIT_MUTATION,
  UPDATE_SUB_UNIT_MUTATION,
  DELETE_SUB_UNIT_MUTATION,
  GET_HIDDEN_UNITS,
  unitsToTree,
  limitedUnitsToTree,
  isChild,
  joinUnitUpdateCache,
  limitedUnitToVisibilityOptions,
  sortByNameAsc,
  getVisibilityOptionsFromVisibilityString
}
