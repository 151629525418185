import React from 'react';
import styled from 'styled-components';
import {LimitedUnit, limitedUnitsToTree} from '../graphql/units';
import {OrbatListItem} from './OrbatListItem';
import {sidcToSymbol} from '../utils/symbols';

interface OrbatProps {
  units: LimitedUnit[]
  hiddenUnits: string[]
  onUnitClick: any
};

const OrbatContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const OrbatRootItem = styled.div`
 width: 100%;
 text-align: left;
 margin-top: ${props => props.theme.spacing.margin}px;
`

const OrbatSymbol = styled.img`
  height: 2em;
  margin-right: 0.5em;
  vertical-align: text-bottom;
`;

const OrbatList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: ${props => props.theme.spacing.margin / 2}px;
  white-space: nowrap;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 1em;
  }
  li {
    margin-left: 0.35em;
    border-left: thin solid #fff;
    text-align: left;
    line-height: 2em;
  }
  li:last-child {
    border-left: none;
  }
  
  li:before {
    width: 0.9em;
    height: 1em;
    margin-right: 0.1em;
    vertical-align: top;
    border-bottom: thin solid #fff;
    content: '';
    display: inline-block;
  }
  
  li:last-child:before {
    border-left: thin solid #fff;  
  }
  
`;

export const Orbat = (props: OrbatProps) => {
  const unitsTree = limitedUnitsToTree(props.units, props.hiddenUnits);
  return (
    <OrbatContainer>
      <OrbatRootItem>
        <OrbatSymbol src={`data:image/svg+xml;utf8,${sidcToSymbol('SUGP------')}`} />
        ORBAT
      </OrbatRootItem>
      <OrbatList id="orbat">
        {
          unitsTree.map(unit => (
            <OrbatListItem key={unit.id} unit={unit} clickHandler={props.onUnitClick}/>
          ))
        }
      </OrbatList>
    </OrbatContainer>
  )
}
