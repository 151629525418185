import React from 'react';
import styled from 'styled-components';

interface LoadingStyledProps {
  size: string
};

interface LodingProps {
  size: string
};

const LoadingStyled = styled.div<LoadingStyledProps>`
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  border: 16px solid #222222;
  border-radius: 50%;
  border-top: 16px solid #1a7392;
  border-right: 16px solid #737272;
  border-bottom: 16px solid #c95200;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
`;

export const Loading = (props: LodingProps) => {
  return (
    <LoadingStyled size={props.size} />
  )
}
