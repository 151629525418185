import { gql } from '@apollo/client';
import { GraphQLAction } from './graphqlAction';
import {LimitedUnit} from './units';
import dayjs from 'dayjs';

export enum FeatureType {
  OBSERVATION = 'OBSERVATION',
  OBJECTIVE = 'OBJECTIVE',
  EVENT_AREA = 'EVENT_AREA',
  MARKER = 'MARKER'
}

export interface Poi {
  id: string
  owner?: LimitedUnit
  visibility: string
  geometry: string
  properties: string
  featureType: FeatureType
  created: number
  expireTime: number
  modified: number
  graphqlAction: GraphQLAction
}

export interface ObservationProperties {
  sidc: string,
  description: string
}

export interface MarkerProperties {
  name: string,
  icon: string,
  iconColor: string
  angle: number
}

export interface CreatePoi {
  featureType: FeatureType
  geometry: string
  properties: string
  visibility: string
  expireTime: number
}

export interface UpdatePoi {
  id: string
  featureType: FeatureType
  geometry: string
  properties: string
  visibility: string
  expireTime: number
}

export interface GetPoisByVisibilityData {
  getPoisByVisibility: Poi[]
}

export interface GetPoiData {
  getPoi: Poi
}

const PoiQuery =`
  id
  owner {
    id
    name
  }
  visibility
  geometry
  properties
  featureType
  created
  expireTime
  modified
  graphqlAction
`

const GET_POI_QUERY = gql`
  query GetPoi($id: ID!) {
    getPoi(id: $id) { 
      ${PoiQuery}
    }
  } 
  `;

const GET_POIS_BY_VISIBILITY_QUERY = gql`
  query GetPoisByVisibility($visibility: String!) {
    getPoisByVisibility(visibility: $visibility) { 
      ${PoiQuery}
    }
  } 
  `;

const CREATE_POI_MUTATION = gql`
  mutation CreatePoi($featureType: String!, $geometry: String!, $properties: String!, $visibility: String!, $expireTime: String) {
    createPoi(featureType: $featureType, geometry: $geometry, properties: $properties, visibility: $visibility, expireTime: $expireTime) {
      ${PoiQuery}
    }
  }
  `;

const UPDATE_POI_MUTATION = gql`
  mutation UpdatePoi($id: ID!, $geometry: String!, $properties: String!, $visibility: String!, $expireTime: String) {
    updatePoi(id: $id, geometry: $geometry, properties: $properties, visibility: $visibility, expireTime: $expireTime) {
      ${PoiQuery}
    }
  } 
  `;

const DELETE_POI_MUTATION = gql`
  mutation DeletePoi($id: ID!) {
    deletePoi(id: $id) {
      ${PoiQuery}
    }
  }
  `;

const POI_UPDATES_BY_VISIBILITY_SUBSCRIPTION = gql`
  subscription OnPoiUpdatesByVisibility($visibility: String!) {
    onPoiUpdates(visibility: $visibility) { 
      ${PoiQuery}
    }
  } 
  `;

const GET_NAVIGATION_TARGET_QUERY = gql`
  query GetNavigationTarget {
    navigationTarget @client
  }
`

const createUpdateCache = (client, result) => {
  const { createPoi } = result.data;
  const data = client.readQuery({
    query: GET_POIS_BY_VISIBILITY_QUERY,
    variables: {
      visibility: createPoi.visibility
    }
  });
  const newData = {
    getPoisByVisibility: [ createPoi, ...data.getPoisByVisibility]
      .sort((a, b) => a.expireTime - b.expireTime)
  }
  client.writeQuery({
    query: GET_POIS_BY_VISIBILITY_QUERY,
    variables: {
      visibility: createPoi.visibility
    },
    data: newData
  });
}

const expiresAfterNow = (poi: Poi): boolean => {
  return poi.expireTime ? dayjs(poi.expireTime).isAfter(dayjs()) : true;
}

const poiUpdatesHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  const newEvent = subscriptionData.data.onPoiUpdates;
  let events;

  switch (newEvent.graphqlAction) {
    case GraphQLAction.CREATE:
      events = [ ...prev.getPoisByVisibility, newEvent]
        .filter(expiresAfterNow);
      break;
    case GraphQLAction.DELETE:
      events = prev.getPoisByVisibility
        .filter(expiresAfterNow)
        .filter(event => event.id !== newEvent.id);
      break;
    case GraphQLAction.UPDATE:
      events = prev.getPoisByVisibility
        .map(event => event.id === newEvent.id ? newEvent : event)
        .filter(expiresAfterNow);
      break;
  }

  return Object.assign({}, prev, {
    getPoisByVisibility: events
  });
}

export {
  GET_POI_QUERY,
  GET_POIS_BY_VISIBILITY_QUERY,
  GET_NAVIGATION_TARGET_QUERY,
  CREATE_POI_MUTATION,
  UPDATE_POI_MUTATION,
  DELETE_POI_MUTATION,
  POI_UPDATES_BY_VISIBILITY_SUBSCRIPTION,
  poiUpdatesHandler,
  createUpdateCache
}