import React, { useContext } from 'react';
import styled from 'styled-components';

import {Marker} from 'react-map-gl';
import {Keyframe} from '../utils/animations';
import {PersonPinIcon} from '../components/icons';
import { UserPositionContext } from './UserPositionProvider';

const UserMarkerStyled = styled.div`
  color: ${props => props.theme.colors.user};
  &:after {
    content: '';
    position: absolute;
    left: 8px;
    top: 24px;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 77, 255, 1);
    animation: ${Keyframe.pulseBlue} 2s infinite;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
`

export const UserMarker = React.memo(() => {
  const { position } = useContext(UserPositionContext)

  return  position ? (
    <Marker
      latitude={position.coords.latitude}
      longitude={position.coords.longitude}
      anchor={'bottom'}
    >
      <UserMarkerStyled>
        <PersonPinIcon />
      </UserMarkerStyled>
    </Marker>
  ) : null;
})
