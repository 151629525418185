import React, { useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';
import { DrawLayer, LayerTypeOption } from '../../graphql/events';
import { LinePaint } from 'mapbox-gl';

interface DrawCustomLayerProps {
  layer: DrawLayer
}

const linePaintOptions = (layer: DrawLayer): LinePaint => {
  return {
    // @ts-ignore
    "line-color": layer.paintOptions.color,
    // @ts-ignore
    "line-opacity": Number.parseFloat(layer.paintOptions.opacity),
    // @ts-ignore
    "line-width": Number.parseFloat(layer.paintOptions.lineWidth)
  }
}

const fillPaintOptions = (layer: DrawLayer): LinePaint => {
  return {
    // @ts-ignore
    "fill-color": layer.paintOptions.color,
    // @ts-ignore
    "fill-opacity": Number.parseFloat(layer.paintOptions.opacity),
  }
}

const LayerPaintOptions = {}
LayerPaintOptions[LayerTypeOption.LINE] =  linePaintOptions
LayerPaintOptions[LayerTypeOption.FILL] =  fillPaintOptions

export const DrawCustomLayer = React.memo((props: DrawCustomLayerProps) => {
  const { layer } = props
  useEffect(() =>{
    //console.log('rendering layer', layer.id)
  }, [layer])
  return (
    <Source id={`${layer.id}-source`} type={'geojson'} data={layer.featureCollection}>
      <Layer
        minzoom={Number.parseFloat(`${layer.minzoom}`)}
        maxzoom={Number.parseFloat(`${layer.maxzoom}`)}
        id={layer.id}
        type={layer.type}
        //@ts-ignore
        paint={LayerPaintOptions[layer.type](layer)}
      />
    </Source>
  )
})
