import React from 'react';
import { BurgerButton } from '../components/BurgerButton';
import { GET_MENU_OPEN } from '../graphql/layout';
import { menuOpenVar } from '../graphql/cache';
import {useQuery} from '@apollo/client';

export const MenuBurgerButton = () => {

  const menuData = useQuery(GET_MENU_OPEN).data;

  const isMenuOpen = menuData.menuOpen;

  const clickHandler = () => {
    menuOpenVar(!isMenuOpen)
  }

  return (
      <BurgerButton
        onClick={clickHandler}
        open={isMenuOpen}
      />
  )
}
