import React, { useContext, useState } from 'react';
import {IconButton} from '../../components/IconButton';
import {CloseIcon, LayersIcon} from '../../components/icons';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Keyframe} from '../../utils/animations';
import { MapStyleContext, MapStyleOption, MapStyleOptions } from '../MapStyleProvider';

const MapStyleSelectorStyled = styled.div`

`
interface MapStyleSelectorListProps {
  isOpen: boolean
}

interface MapStyleSelectorItemProps {
  selected: boolean
}

const MapStyleSelectorContainer = styled.div<MapStyleSelectorListProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.colors.background.dark};
  z-index: 10;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  animation: ${Keyframe.fadeIn} 0.3s ease;
`

const MapStyleSelectorList = styled.ul`
  margin: 0;
  list-style: none;
  padding: ${props => props.theme.spacing.padding}px;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  left: ${props => props.theme.spacing.margin}px;
`;

const MapStyleSelectorListItem = styled.li<MapStyleSelectorItemProps>`
  background-color: ${({ selected, theme}) => selected ? theme.colors.background.default : theme.colors.background.dark};
  height: ${props => props.theme.spacing.default * 3}px;
  line-height: ${props => props.theme.spacing.default * 3}px;
  font-size: ${props => props.theme.font.size.large};
`

export const MapStyleSelector = React.memo((props) => {
  const { t } = useTranslation();
  const [selectorOpen, setSelectorOpen] = useState(false);
  const { selectedStyleOption, setSelectedStyleOption } = useContext(MapStyleContext)

  const selectHandler = (styleOption: MapStyleOption) => {
    setSelectedStyleOption(styleOption)
    setSelectorOpen(false)
  }

  return  (
    <MapStyleSelectorStyled>
      <MapStyleSelectorContainer
        isOpen={selectorOpen}
      >
        <CloseButtonContainer>
          <IconButton size={'lg'} clickHandler={() => setSelectorOpen(false)}>
            <CloseIcon />
          </IconButton>
        </CloseButtonContainer>

        <h3>{t('map.select')}</h3>
        <MapStyleSelectorList>
          {MapStyleOptions.map(option => (
            <MapStyleSelectorListItem selected={option.id === selectedStyleOption.id} key={option.id} onClick={() => selectHandler(option)}>
              {t(`map.${option.id}`)}
            </MapStyleSelectorListItem>
          ))}
        </MapStyleSelectorList>
      </MapStyleSelectorContainer>

      <IconButton clickHandler={() => setSelectorOpen(!selectorOpen)} size={'lg'}>
        <LayersIcon />
      </IconButton>
    </MapStyleSelectorStyled>
  );
})
