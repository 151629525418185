import { gql } from '@apollo/client';

export const GET_BODY_HEIGHT = gql`
  query GetBodyHeight {
    bodyHeight @client
  }
`;

export const GET_BODY_WIDTH = gql`
  query GetBodyWidth($bodyWidth: Int!) {
    bodyWidth @client
  }
`;

export const GET_MENU_OPEN = gql`
  query GetMenuOpen {
    menuOpen @client
  }
`;

export const GET_MAP_STYLE = gql`
  query GetMapStyle {
    mapStyle @client
  }
`;
