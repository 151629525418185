import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IconButton } from '../../components/IconButton';
import { SmallPersonPinIcon, TargetLockIcon } from '../../components/icons';
import { MapChildProps } from '../MapPage';
import { UserPositionContext, UserPositionMode } from '../../user/UserPositionProvider';

// Function that attempts to request a screen wake lock.


export const UserPositionButton = React.memo((props: MapChildProps) => {
  const { position, positionMode, setPositionMode } = useContext(UserPositionContext)
  const { flyToCallback } = props
  const [wakeLock, setWakeLock] = useState(null)

  useEffect(() => {
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          //@ts-ignore
          const wakeLock = await navigator.wakeLock.request();
          setWakeLock(wakeLock)
        }
      } catch (err) {
        console.error(`${JSON.stringify(err)}`);
      }
    };
    if(positionMode === UserPositionMode.TRACK_USER) {
      if(!wakeLock) {
        requestWakeLock()
      }
    } else {
      //@ts-ignore
      wakeLock?.release()
      if(wakeLock) {
        setWakeLock(null)
      }
    }
  }, [wakeLock, positionMode])

  const clickHandler = useCallback(async () => {
    if(positionMode === UserPositionMode.DIRTY) {
      setPositionMode(UserPositionMode.USER_POSITION)
      if(position) {
        flyToCallback({
          lng: position.coords.longitude,
          lat: position.coords.latitude,
          zoom: 15
        })
      }
    }
    if(positionMode === UserPositionMode.USER_POSITION) {
      setPositionMode(UserPositionMode.TRACK_USER)
    }
    if(positionMode === UserPositionMode.TRACK_USER) {
      setPositionMode(UserPositionMode.USER_POSITION)
    }
  }, [positionMode, setPositionMode, position, flyToCallback])

  return  (
    <IconButton clickHandler={clickHandler} size={'lg'} color={positionMode === UserPositionMode.TRACK_USER ? '#ff0000' : undefined}>
      {positionMode === UserPositionMode.DIRTY ? (
        <SmallPersonPinIcon />
      ) : (
        <TargetLockIcon />
      )}
    </IconButton>
  );
})
