import ms from 'milsymbol';
import dayjs from 'dayjs';

const OBSERVATION_DEFAULT_EXPIRE_TIME_SECONDS = 3600;

const Affiliation = {
  'U': 'UNKNOWN',
  'H': 'HOSTILE',
  'N': 'NEUTRAL',
  'F': 'FRIENDLY'
}

const UnitSize = {
  '-': 'UNDEFINED',
  'A': 'TEAM',
  'B': 'SQUAD',
  'C': 'SECTION',
  'D': 'PLATOON',
  'E': 'COMPANY',
  'F': 'BATTALION',
  'G': 'REGIMENT'
}

const FunctionId = {
  'U': 'UNDEFINED',
  'UCD': 'AIR_DEFENCE',
  'UCAA': 'ANTI_TANK',
  'UCA': 'ARMOUR',
  'UCF': 'FIELD_ARTILLERY',
  'UCE': 'ENGINEER',
  'UCI': 'INFANTRY',
  'UCIZ': 'MECHANIZED_INFANTRY',
  'UCIM': 'MOTORIZED_INFANTRY',
  'USM': 'MEDICAL',
  'UCR': 'RECONNAISSANCE',
  'USS': 'SUPPLY',
  'UST': 'TRANSPORTATION'
}

const affiliationOptions = (t: any) => {
  return Object.keys(Affiliation)
    .map(key => {
      return {
        id: key,
        name: t(`units.affiliation.${key}`)
      }
    })
}

const unitSizeOptions = (t: any) => {
  return Object.keys(UnitSize)
    .map(key => {
      return {
        id: key,
        name: t(`units.size.${key}`)
      }
    })
}

const functionIdOptions = (t: any) => {
  return Object.keys(FunctionId)
    .map(key => {
      return {
        id: key,
        name: t(`units.functionId.${key}`)
      }
    })
}

const getSidcAffiliation = (sidc: string): string => {
  return sidc.substring(1, 2);
}

const setSidcAffiliation = (sidc: string, affiliation: string): string => {
  return `${sidc.substring(0, 1)}${affiliation}${sidc.substring(2, 12)}`;
}

const getSidcUnitSize = (sidc: string): string => {
  return sidc.substring(11, 12);
}

const setSidcUnitSize = (sidc: string, unitSize: string): string => {
  return `${sidc.substring(0, 11)}${unitSize}`;
}

const getSidcFunctionId = (sidc: string): string => {
  return sidc.substring(4, 10).split('-')[0]
}

const setSidcFunctionId = (sidc: string, functionId: string): string => {
  const prefix = sidc.substring(0, 4);
  const suffix = sidc.substring(10, 12);
  let fnId = functionId;
  while(fnId.length < 6) {
    fnId += '-'
  }
  return `${prefix}${fnId}${suffix}`;
}

const getFillOpacityByExpireTime = (expireTime: number): number => {
  const now = dayjs();
  const expire = dayjs(expireTime);
  const diffSeconds = expire.diff(now) / 1000;
  const ratio = diffSeconds / OBSERVATION_DEFAULT_EXPIRE_TIME_SECONDS;
  return 0.5 + ratio / 2;
}

const sidcToMilSymbol = (sidc: string, uniqueDesignation?: string, additionalInfo?: string, noOutline?: boolean, fillOpacity?: number) => {
  return new ms.Symbol(sidc,{
    size:30,
    outlineWidth: noOutline ? 0 : 1,
    uniqueDesignation: uniqueDesignation,
    additionalInformation: additionalInfo,
    fillOpacity: fillOpacity || 1
  });
}

const sidcToUnitMapMilSymbol = (sidc: string, uniqueDesignation: string, additionalInfo?: string, higherFormation?: string) => {
  return new ms.Symbol(sidc,{
    size:30,
    outlineWidth: 1,
    uniqueDesignation: uniqueDesignation,
    additionalInformation: additionalInfo || '',
    higherFormation: higherFormation || ''
  });
}

const sidcToSymbol = (sidc: string, uniqueDesignation?: string, additionalInfo?: string, noOutline?: boolean) => {
  return new ms.Symbol(sidc,{
    size:30,
    outlineWidth: noOutline ? 0 : 1,
    uniqueDesignation: uniqueDesignation,
    additionalInformation: additionalInfo
  }).asSVG();
}

const sidcToSymbolOrbat = (sidc: string, follow: boolean) => {
  return sidcToMilSymbol(sidc, undefined, undefined, undefined, follow ? 1.0 : 0.1)
    .asSVG();
}

export {
  Affiliation,
  UnitSize,
  FunctionId,
  getSidcAffiliation,
  setSidcAffiliation,
  getSidcUnitSize,
  setSidcUnitSize,
  getSidcFunctionId,
  setSidcFunctionId,
  affiliationOptions,
  unitSizeOptions,
  functionIdOptions,
  sidcToSymbol,
  sidcToMilSymbol,
  sidcToSymbolOrbat,
  sidcToUnitMapMilSymbol,
  getFillOpacityByExpireTime
};
