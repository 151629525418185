import React, {useState} from 'react';

import {FullPage} from '../components/FullPage';
import {Label} from '../components/Label';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useMutation, useQuery} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {GET_ACTIVE_UNIT_QUERY, GetActiveUnitData} from '../graphql/units';

import {
  affiliationOptions,
  functionIdOptions,
  setSidcAffiliation,
  setSidcFunctionId,
  setSidcUnitSize,
  sidcToSymbol,
  unitSizeOptions
} from '../utils/symbols';
import styled from 'styled-components';
import {
  CREATE_POI_MUTATION,
  CreatePoi,
  FeatureType,
  ObservationProperties
} from '../graphql/pois';
import {LatLon} from 'geodesy/mgrs';
import {Geometry} from 'geojson';
import dayjs from 'dayjs';

type Inputs = {
  description: string,
  sidc: string,
};

const UnitSymbolContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.margin}px;
`;

const UnitSymbol = styled.img`
  height: 5em;
`;

export const NewObservationPage = () => {
  const history = useHistory();
  const { lon, lat } = useParams();
  const { t } = useTranslation();

  const [affiliation, setAffiliation] = useState<string>('H');
  const [unitSize, setUnitSize] = useState<string>('-');
  const [unitFunction, setUnitFunction] = useState<string>('U');

  const longitude = parseFloat(lon);
  const latitude = parseFloat(lat);

  const mgrs = new LatLon(latitude, longitude).toUtm().toMgrs().toString()
  const { data: activeUnit, loading: getActiveUnitLoading } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);

  const { register, handleSubmit, errors, formState, setValue, watch } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      sidc: 'SHGPU-------',
      description: ''
    }
  });

  const sidc = watch('sidc');

  const affiliationChanged = ({ target }) => {
    setAffiliation(target.value);
    const newSidc = setSidcAffiliation(sidc, target.value);
    setValue('sidc', newSidc);
  }

  const unitSizeChanged = ({ target }) => {
    setUnitSize(target.value);
    const newSidc = setSidcUnitSize(sidc, target.value);
    setValue('sidc', newSidc);
  }

  const unitFunctionChanged = ({ target }) => {
    setUnitFunction(target.value);
    const newSidc = setSidcFunctionId(sidc, target.value);
    setValue('sidc', newSidc);
  }

  const allAffiliationOptions = affiliationOptions(t);
  const sizeOptions = unitSizeOptions(t);
  const functionOptions = functionIdOptions(t);

  const [createPoi, { loading }] = useMutation(CREATE_POI_MUTATION);

  const onSubmit = (formData) => {
    console.log(formData)

    const geometry: Geometry = {
      type: 'Point',
      coordinates: [longitude, latitude]
    }

    const properties: ObservationProperties = {
      description: formData.description,
      sidc
    }

    const createObservation: CreatePoi = {
      featureType: FeatureType.OBSERVATION,
      visibility: activeUnit?.getActiveUnit.visibilities[0].visibility || '',
      geometry: JSON.stringify(geometry),
      properties: JSON.stringify(properties),
      expireTime: dayjs().add(1, 'hour').unix() * 1000
    }

    createPoi({
      variables: createObservation
    }).then(({ data }) => {
      history.push(`/observations/view/${data.createPoi.id}`)
    })

  };

  return (
    <FullPage
      heading={t('observations.new')}
      loading={loading || getActiveUnitLoading}
      closeHandler={() => history.push(`/`)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <UnitSymbolContainer>
          <UnitSymbol src={`data:image/svg+xml;utf8,${sidcToSymbol(sidc, '')}`} />
        </UnitSymbolContainer>

        <Label htmlFor={'affiliation'} hasError={'false'}>
          {t('units.unitAffiliation')}
        </Label>
        <select value={affiliation} onChange={affiliationChanged} name="affiliation">
          {
            allAffiliationOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>

        <Label htmlFor={'unitSize'} hasError={'false'}>
          {t('units.unitSize')}
        </Label>
        <select value={unitSize} onChange={unitSizeChanged} name="unitSize">
          {
            sizeOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>

        <Label htmlFor={'unitFunction'} hasError={'false'}>
          {t('units.unitFunction')}
        </Label>
        <select id="unitFunction" value={unitFunction} onChange={unitFunctionChanged} name="unitFunction">
          {
            functionOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>

        <Label htmlFor={'sidc'} hasError={errors.sidc ? 'true' : 'false'}>
          {t('sidc')}{errors.sidc && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ required: true, maxLength: 12, minLength: 12 })} type="text" id="sidc" name="sidc" placeholder={t('sidc')} />

        <Label htmlFor={'mgrs'} hasError={'false'}>
          {t('mgrsCoordinates')}
        </Label>
        <input disabled type="text" id="mgrs" name="mgrs" value={mgrs} />

        <Label htmlFor={'description'} hasError={'false'}>
          {t('description')}
        </Label>
        <textarea ref={register} id="description" name="description" placeholder={t('description')} />

        <input disabled={!formState.isValid || !activeUnit} type="submit" value={t('observations.create') as string}/>
      </form>

    </FullPage>
  )
}
