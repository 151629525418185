import React from 'react';
import { useParams } from 'react-router-dom';

import {GeneralMarker} from './GeneralMarker';

export const MapPoint = React.memo(() => {
  const { lon, lat } = useParams();

  return (
    <GeneralMarker latitude={parseFloat(lat)} longitude={parseFloat(lon)} />
  );
})
