import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';

import {GET_FACTION_UNITS_QUERY, GET_HIDDEN_UNITS, GetFactionUnitsData, LimitedUnit} from '../graphql/units';

import {UnitMarker} from './UnitMarker';

export const UnitMarkers = React.memo(() => {
  const { data: factionUnitsData, loading } = useQuery<GetFactionUnitsData>(GET_FACTION_UNITS_QUERY, {
    pollInterval: 30000
  });
  const { data: hiddenUnitsData } = useQuery(GET_HIDDEN_UNITS);
  const { hiddenUnits } = hiddenUnitsData;

  const findParent = (unit: LimitedUnit, factionUnits: LimitedUnit[]) => {
    return unit.parentId ? factionUnits
      .find(parent => `${parent.id}` === `${unit.parentId}`) : undefined;
  }

  return (
    <Fragment>
      {!loading && factionUnitsData?.getFactionUnits
        .filter(unit => unit.device)
        .filter(unit => !hiddenUnits.includes(unit.id))
        .map(unit => (
          <UnitMarker unit={unit} key={unit.id} parent={findParent(unit, factionUnitsData?.getFactionUnits)} />
        ))}
    </Fragment>
  )
})
