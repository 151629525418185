import React from 'react';
import styled from 'styled-components';

interface GridSelectorOptionStyledProps {
  selected: boolean
}

interface GridSelectorOptionProps {
  selected: boolean
  clickHandler: any
  children: any
}

export const GridSelectorIconOption = styled.div`
`

interface GridSelectorColorOptionProps {
  color: string
}

export const GridSelectorColorOption = styled.div<GridSelectorColorOptionProps>`
  width: ${props => props.theme.spacing.default*1.5}px;
  height: ${props => props.theme.spacing.default*1.5}px;
  background-color: ${props => props.color};
`

const GridSelectorOptionStyled = styled.div<GridSelectorOptionStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.main};
  width: ${props => props.theme.spacing.default*3-2}px;
  height: ${props => props.theme.spacing.default*3-2}px;
  background-color: ${({ theme, selected }) => selected ? theme.colors.background.light : theme.colors.background.default};
`

export const GridSelectorOption = (props: GridSelectorOptionProps) => {

  return (
    <GridSelectorOptionStyled onClick={props.clickHandler} selected={props.selected}>
      {props.children}
    </GridSelectorOptionStyled>
  )
}
