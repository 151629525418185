import React, { useEffect } from 'react';
import debounce from 'lodash/debounce'
import { Heading } from '../components/Heading';
import { LogoWithText } from '../logo/LogoWithText';
import { MenuBurgerButton } from './MenuBurgerButton';
import { bodyHeightVar, bodyWidthVar } from '../graphql/cache';
import {Navigation} from './Navigation';

export const MainHeading = React.memo(() => {
  useEffect(() => {
    const onResize = () => {
      const element = document.getElementById('main-container');
      let vh = window.innerHeight * 0.01;
      element?.style.setProperty('--vh', `${vh}px`);
      if (element) {
        bodyHeightVar(window.innerHeight);
        bodyWidthVar(element.offsetWidth);
      }
    }
    onResize();
    window.addEventListener('resize', debounce(onResize, 300));
  },[])

  return (
    <Heading>
      <MenuBurgerButton />
      <LogoWithText />
      <Navigation />
    </Heading>
  )
})
