import React, { useContext } from 'react';
import { Layer, Source } from 'react-map-gl';
import { UserPositionContext } from '../user/UserPositionProvider';
import circle from '@turf/circle';
import LatLon from 'geodesy/latlon-spherical';
import { latLngToPoint } from '../utils/geo';
import { MapStyleContext, MapStyleOption } from './MapStyleProvider';

interface UserPerimeterProps {
  minZoom: number,
  maxZoom: number,
  radiusMeters: number
}

const font = (selectedStyle: MapStyleOption): string => {
  return selectedStyle.id !== 'maastokartta' ? 'Open Sans Regular,Arial Unicode MS Regular' : 'Liberation Sans NLSFI'
}

export const UserPerimeter = React.memo((props: UserPerimeterProps) => {
  const { position: userPosition } = useContext(UserPositionContext)
  const { selectedStyleOption } = useContext(MapStyleContext)
  const { minZoom, maxZoom, radiusMeters } = props

  const center = [userPosition?.coords.longitude || 0, userPosition?.coords.latitude || 0];

  const centerPoint = new LatLon(userPosition?.coords.latitude || 0, userPosition?.coords.longitude || 0)
  const labelPoint: LatLon = centerPoint.destinationPoint(radiusMeters, 0)

  const labelGeojson = latLngToPoint(labelPoint, {label: `${radiusMeters}m`})
  const circleGeojson = circle(center, radiusMeters/1000, { units: 'kilometers' });

  return userPosition && (
    <>
      <Source id={`user-perimeter-${radiusMeters}m`} type={'geojson'} data={circleGeojson}>
        <Layer
          minzoom={minZoom}
          maxzoom={maxZoom}
          id={`user-perimeter-${radiusMeters}m`}
          type="line"
          paint={{
            "line-color": "hsl(0,100%,79%)",
            "line-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              minZoom,
              1,
              maxZoom,
              0.1
            ],
            "line-width": [
              "interpolate",
              ["exponential", 1.5],
              ["zoom"],
              minZoom,
              1,
              maxZoom,
              1.8
            ],
          }}
        />
      </Source>
      <Source id={`user-perimeter-${radiusMeters}m-label`} type={'geojson'} data={labelGeojson}>
        <Layer
          id={`user-perimeter-${radiusMeters}m-label`}
          minzoom={minZoom}
          maxzoom={maxZoom}
          type={'symbol'}
          paint={{"text-color": "#f29797"}}
          layout={{
            "text-size": 12,
            "text-anchor": "center",
            "text-field": ["to-string", ["get", "label"]],
            "text-font": [font(selectedStyleOption)]}}
        />
      </Source>
    </>
  )
})
