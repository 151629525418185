import React, { Fragment } from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Loading} from '../components/Loading';
import {ApolloError} from 'apollo-client';
import { useQuery } from '@apollo/client';
import {GET_USER_QUERY, GetUserData} from '../graphql/users';
import {GET_ACTIVE_UNIT_QUERY, GetActiveUnitData} from '../graphql/units';
import {Redirect} from 'react-router-dom';
import { Keyframe } from '../utils/animations';
import {LogoWithText} from '../logo/LogoWithText';
import {Heading} from '../components/Heading';

const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.secondary};
`

const LoaderContainer = styled.div`
  margin: ${props => props.theme.spacing.margin*2}px 0;
`

const GlobalLoadingContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.background.dark};
  width: 100vw;
  height: 100vh;
  color: ${props => props.theme.colors.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  animation: ${Keyframe.fadeIn} 0.3s ease;
  padding-top: ${props => props.theme.spacing.default*4}px;
`;

export const GlobalLoadingContainer = (props: any) => {
  const { t } = useTranslation();
  const { loading: getUserLoading, error: getUserError } = useQuery<GetUserData>(GET_USER_QUERY);
  const { loading: getActiveUnitLoading, error: getActiveUnitError } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);

  const errors: ApolloError[] = [];
  getUserError && errors.push(getUserError);

  let component = (
    <Fragment>
      {props.children}
    </Fragment>
  );
  if (getActiveUnitError) {
    component = (<Redirect to={'join'} />);
  }
  else if (errors.length > 0) {
    component = (
      <GlobalLoadingContainerStyled>
        <h1>{t('error')}</h1>
        {props.errors?.map(error => (
          <ErrorMessage>{error?.name}: {error?.message}</ErrorMessage>
        ))}
      </GlobalLoadingContainerStyled>
    );
  } else if(getUserLoading || getActiveUnitLoading) {
    component = (
      <GlobalLoadingContainerStyled>
        <Heading>
          <LogoWithText />
        </Heading>
        <LoaderContainer>
          <Loading size={'lg'}/>
          <h2>{t('loading')}</h2>
        </LoaderContainer>
      </GlobalLoadingContainerStyled>
    );
  }

  return component;
}
