import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_BODY_HEIGHT } from '../graphql/layout';
import { Loading } from '../components/Loading';

interface StyledPageContentProps {
  bodyHeight: number
  loading: string
  noPadding?: boolean
  fullWidth?: boolean
};

interface PageContentProps {
  loading: boolean
  children?: any
  heading?: string
  noPadding?: boolean
  fullWidth?: boolean
};

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPageContent = styled.div<StyledPageContentProps>`
  background-color: ${props => props.theme.colors.background.default};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.loading === 'true' ? 'center' : 'flex-start'};
  position: relative;
  top: ${props => props.theme.spacing.default*4}px;
  height: ${props => props.bodyHeight - props.theme.spacing.default*4}px;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: ${props => props.fullWidth ? '100%': 'calc(100% - 300px)'};
  }
  overflow-y: auto;
  z-index: 2;
  padding: 0 ${({ theme, noPadding }) => noPadding ? 0 : theme.spacing.padding}px;
`;

export const PageContent = (props: PageContentProps) => {
  const { data } = useQuery(GET_BODY_HEIGHT);
  const { t } = useTranslation();
  return (
    <StyledPageContent fullWidth={props.fullWidth} noPadding={props.noPadding} loading={props.loading ? 'true' : 'false'} bodyHeight={data.bodyHeight}>
      {props.loading ?
        <LoaderContainer>
          <h1>{props.heading || ''}</h1>
          <Loading size={'lg'}/>
          <h2>{t('loading')}</h2>
        </LoaderContainer>
        : props.children }
    </StyledPageContent>
  )
}
