import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';

import {
  GET_ACTIVE_UNIT_QUERY,
  GET_FACTION_UNITS_QUERY,
  GetActiveUnitData,
  GetFactionUnitsData,
  getVisibilityOptionsFromVisibilityString,
  limitedUnitsToTree,
  limitedUnitToVisibilityOptions
} from '../graphql/units';
import {PoiMarkersByVisibility} from './PoiMarkersByVisibility';

export const PoiMarkers = React.memo(() => {
  const { data: activeUnit, loading: activeUnitLoading } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);
  const { data: factionUnitsData, loading: factionUnitsLoading } = useQuery<GetFactionUnitsData>(GET_FACTION_UNITS_QUERY);
  const factionVisibility = activeUnit?.getActiveUnit.visibilities[0].visibility || '';
  const userUnitId = activeUnit?.getActiveUnit.id;

  const loading = activeUnitLoading || factionUnitsLoading;

  const unitTree = factionUnitsData && userUnitId ? limitedUnitsToTree(factionUnitsData.getFactionUnits): [];

  const pattern = new RegExp(`^.*-${userUnitId}$`)

  const userUnitOption = unitTree
    .map(unit => limitedUnitToVisibilityOptions('', factionVisibility, unit))
    .reduce((prev, cur) => {
      return prev.concat(cur);
    },[])
    .filter(option => option.visibility.match(pattern) !== null)
    .map(option => option.visibility)[0];

  const visibilities = !loading && userUnitOption ? getVisibilityOptionsFromVisibilityString(userUnitOption) : null;

  return (
    <Fragment>
      {!loading && visibilities && visibilities.map(visibility => (
        <PoiMarkersByVisibility key={visibility} visibility={visibility} />
      ))}
    </Fragment>
  )
})
