import React, { useRef } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import {BottomMenu} from '../components/BottomMenu';
import {ObservationBottomMenu} from '../pois/ObservationBottomMenu';
import {PointBottomMenu} from '../pois/PointBottomMenu';
import {MarkerBottomMenu} from '../pois/MarkerBottomMenu';
import {useQuery} from '@apollo/client';
import {GET_BODY_HEIGHT} from '../graphql/layout';
import {UnitBottomMenu} from '../units/UnitBottomMenu';
import { MapChildProps } from './MapPage';

export const MapBottomMenu = React.memo((props: MapChildProps) => {
  const history = useHistory();
  const location = useLocation();
  const { bodyHeight } = useQuery(GET_BODY_HEIGHT).data;
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const bottomMenuOpen = location.pathname;

  const closeHandler = () => {
    history.push('/')
  }

  return (
    <BottomMenu
      refNode={node}
      closeHandler={closeHandler}
      open={bottomMenuOpen}
      maxHeight={bodyHeight}
    >
      <Route path="/units/view/:unitId">
        <UnitBottomMenu  {...props} />
      </Route>
      <Route path="/point/:lon/:lat">
        <PointBottomMenu />
      </Route>
      <Route path="/observations/view/:poiId">
        <ObservationBottomMenu {...props} />
      </Route>
      <Route path="/markers/view/:poiId">
        <MarkerBottomMenu {...props} />
      </Route>
    </BottomMenu>
  );
})
