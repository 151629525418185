import React from 'react';

import { DrawCustomLayer } from './layers/DrawCustomLayer';
import { EventArea } from '../graphql/events';

interface EventAreaLayersProps {
  area: EventArea
}

export const EventAreaLayers = React.memo((props: EventAreaLayersProps) => {
  const { area } = props

  return (
    <>
      {area.areaLayers.map(layer => (
        <DrawCustomLayer key={layer.id} layer={layer} />
      ))}
    </>
  )
})
