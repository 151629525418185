import React, { useEffect, Fragment } from 'react';
import { useQuery } from '@apollo/client';

import {
  FeatureType,
  GET_POIS_BY_VISIBILITY_QUERY,
  GetPoisByVisibilityData,
  POI_UPDATES_BY_VISIBILITY_SUBSCRIPTION,
  poiUpdatesHandler
} from '../graphql/pois'
import {ObservationMarker} from './ObservationMarker';
import {MarkerMarker} from './MarkerMarker';

interface PoiMarkersByVisibilityProps {
  visibility: string
}

export const PoiMarkersByVisibility = React.memo((props: PoiMarkersByVisibilityProps) => {

  const { subscribeToMore, data } = useQuery<GetPoisByVisibilityData>(GET_POIS_BY_VISIBILITY_QUERY, {
    variables: props
  });

  useEffect(() => {
    let unsub;
    const errorHandler = error => {
      console.log('reconnecting', error, props);
      unsub = subscribeToMore({
        document: POI_UPDATES_BY_VISIBILITY_SUBSCRIPTION,
        variables: props,
        updateQuery: poiUpdatesHandler,
        onError: errorHandler
      })
    }
    unsub = subscribeToMore({
      document: POI_UPDATES_BY_VISIBILITY_SUBSCRIPTION,
      variables: props,
      updateQuery: poiUpdatesHandler,
      onError: errorHandler
    })
    return function cleanup() { unsub(); }
  }, [subscribeToMore, props])

  const observations = data?.getPoisByVisibility.filter(poi => poi.featureType === FeatureType.OBSERVATION);
  const markers = data?.getPoisByVisibility.filter(poi => poi.featureType === FeatureType.MARKER);

  return (
    <Fragment>
      { observations?.map(observation => (
        <ObservationMarker key={observation.id} poi={observation} />
      ))}
      { markers?.map(marker => (
        <MarkerMarker key={marker.id} poi={marker} />
      ))}
    </Fragment>
  )
})
