import React from 'react';
import { Source } from 'react-map-gl';

export const MBCompositeSource = (props: any) => {
  return (
    <Source
      id={'mgrs'}
      type={'vector'}
      url={'mapbox://command-tracker.5igk00ip,command-tracker.74cspm1m,command-tracker.600mt1nd,command-tracker.1u0bftfm,command-tracker.8ekzyqsl,command-tracker.avs87pql,command-tracker.332nig6l,command-tracker.ckdpsldjb3b0n24muej0e0fmz-40izs,command-tracker.9ztonq7o,command-tracker.6lbhop51,command-tracker.9skot11p,command-tracker.ckdpt416i0tgi22mubij65foo-6yhza'}
    >
      {props.children}
    </Source>
  )
}
