import React, {Fragment, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {ActionMenuItem} from '../components/ActionMenuItem';
import {DeleteIcon, DirectionsIcon, EditIcon} from '../components/icons';
import {GET_POI_QUERY, GetPoiData, MarkerProperties} from '../graphql/pois';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {LatLon} from 'geodesy/mgrs';
import {Point} from 'geojson';
import dayjs from 'dayjs';
import {ActionList} from '../components/BottomMenu';
import {useQuery} from '@apollo/client';
import {navigationTargetVar} from '../graphql/cache';
import {IconButton} from '../components/IconButton';
import { MapChildProps } from '../map/MapPage';

const DataItem = styled.li`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.margin}px;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing.padding}px;
`;

const DataTable = styled.table`
  font-size: ${props => props.theme.font.size.default};
  tr {
    text-align: left;
  }
`
const TdHeading = styled.td`
  padding-right: ${props => props.theme.spacing.padding}px;
  vertical-align: top;
  text-wrap: none;
`

const NavigationButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  right: ${props => props.theme.spacing.margin}px;
`;

export const MarkerBottomMenu = (props: MapChildProps) => {
  const { t } = useTranslation();
  const { poiId } = useParams();
  const { flyToCallback } = props
  const { data: getPoiData } = useQuery<GetPoiData>(GET_POI_QUERY, {
    variables: {
      id: poiId
    }
  });

  const marker = getPoiData?.getPoi;
  const properties: MarkerProperties = marker ? JSON.parse(marker.properties) : null;
  const point: Point = marker ? JSON.parse(marker.geometry) : null;

  useEffect(() => {

    if(point) {
      flyToCallback({
        lng: point.coordinates[0],
        lat: point.coordinates[1],
        zoom: 14.5
      })
    }
  }, [point, flyToCallback])

  const mgrs = marker ? new LatLon(point.coordinates[1], point.coordinates[0]).toUtm().toMgrs().toString() : ''

  const navigateToClickHandler = (point: Point) => {
    navigationTargetVar({
      name: properties?.name || '',
      point
    });
  }

  return marker ? (
    <Fragment>
      <NavigationButtonContainer>
        <IconButton size={'lg'} clickHandler={() => navigateToClickHandler(point)}>
          <DirectionsIcon />
        </IconButton>
      </NavigationButtonContainer>
      <h3>{properties.name}</h3>
      <ActionList>
        <DataItem>
          <DataTable>
            <tbody>
            <tr>
              <TdHeading>MGRS:</TdHeading>
              <td>{mgrs}</td>
            </tr>
            <tr>
              <TdHeading>{t('created')}:</TdHeading>
              <td>{dayjs(marker?.created).format('HH:mm DD.MM.YYYY')}</td>
            </tr>
            <tr>
              <TdHeading>{t('creator')}:</TdHeading>
              <td>{marker?.owner?.name}</td>
            </tr>
            {
              marker?.modified ? (
                <tr>
                  <TdHeading>{t('modified')}:</TdHeading>
                  <td>{dayjs(marker.modified).format('HH:mm DD.MM.YYYY')}</td>
                </tr>
              ) : null
            }
            {
              marker?.expireTime ? (
                <tr>
                  <TdHeading>{t('expires')}:</TdHeading>
                  <td>{dayjs(marker.expireTime).format('HH:mm DD.MM.YYYY')}</td>
                </tr>
              ) : null
            }
            </tbody>
          </DataTable>
        </DataItem>
        <ActionMenuItem>
          <EditIcon />
          <Link to={`/markers/edit/${poiId}`}>
            {t('edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <DeleteIcon />
          <Link to={`/markers/delete/${poiId}`}>
            {t('delete')}
          </Link>
        </ActionMenuItem>
      </ActionList>
    </Fragment>
  ) : null
}
