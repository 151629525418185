import React, {useState} from 'react';

import {FullPage} from '../components/FullPage';
import {Label} from '../components/Label';
import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useMutation, useQuery} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {
  GET_ACTIVE_UNIT_QUERY,
  GET_FACTION_UNITS_QUERY,
  GetActiveUnitData,
  GetFactionUnitsData, getVisibilityOptionsFromVisibilityString,
  limitedUnitsToTree, limitedUnitToVisibilityOptions, VisibilityOption
} from '../graphql/units';

import styled from 'styled-components';
import {
  FeatureType, GET_POI_QUERY, GetPoiData, MarkerProperties, UPDATE_POI_MUTATION, UpdatePoi
} from '../graphql/pois';
import {LatLon} from 'geodesy/mgrs';
import {Point} from 'geojson';
import dayjs from 'dayjs';
import {GridSelector} from '../components/GridSelector';
import {GridSelectorColorOption, GridSelectorIconOption, GridSelectorOption} from '../components/GridSelectorOption';
import {markerIconColorOptions, markerIconOptions} from '../components/icons';
const TimeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%
`

const TimeInput = styled.input`
  width: auto !important;
`

const DateInput = styled.input`
  width: auto !important;
`

interface Inputs {
  name: string
  expireDate: string
  expireTime: string
  degrees: number
}

export const EditMarkerPage = () => {
  const history = useHistory();
  const { poiId } = useParams();
  const { t } = useTranslation();

  const [visibility, setVisibility] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [iconColor, setIconColor] = useState<string>('');

  const { data: activeUnitData, loading: getActiveUnitLoading } = useQuery<GetActiveUnitData>(GET_ACTIVE_UNIT_QUERY);
  const { data: factionUnitsData } = useQuery<GetFactionUnitsData>(GET_FACTION_UNITS_QUERY);

  const activeUnit = activeUnitData?.getActiveUnit;
  const userUnitId = activeUnit?.id;

  const unitTree = factionUnitsData && userUnitId ? limitedUnitsToTree(factionUnitsData.getFactionUnits) : [];

  const pattern = new RegExp(`^.*-${userUnitId}$`)

  const unitVisibility = activeUnit?.visibilities[0].visibility || '';

  const factionOption: VisibilityOption = {
    id: 0,
    visibility: unitVisibility,
    name: t('units.factionVisible')
  }

  const optionsTree = unitTree
    .map(unit => limitedUnitToVisibilityOptions('', unitVisibility, unit))
    .reduce((prev, cur) => {
      return prev.concat(cur);
    },[]);

  const userUnitOption = optionsTree.find(option => option.visibility.match(pattern) !== null);
  const visibilities = userUnitOption ? getVisibilityOptionsFromVisibilityString(userUnitOption.visibility) : null;

  const parentOptions = optionsTree
    .filter(option => visibilities?.includes(option.visibility))
    .map(visibilityOption => {
      if(visibilityOption.id === activeUnit?.id) {
        return {
          ...visibilityOption,
          name: `${visibilityOption.name} (${t('units.myUnit')})`
        }
      } else {
        return visibilityOption;
      }
    });

  const visiblityOptions = [factionOption, ...parentOptions];

  const { register, handleSubmit, errors, formState, watch, reset } = useForm<Inputs>({mode: 'onChange'});

  const onPoiLoaded = ({ getPoi }: GetPoiData) => {
    const properties: MarkerProperties = JSON.parse(getPoi.properties);
    const expire = dayjs(getPoi.expireTime);
    reset({
      name: properties.name,
      expireDate: expire.format('YYYY-MM-DD'),
      expireTime: expire.format('HH:mm'),
      degrees: properties.angle
    })
    setIcon(properties.icon);
    setIconColor(properties.iconColor);
    setVisibility(getPoi.visibility);
  }

  const { data: getPoiData, loading: getPoiLoading } = useQuery<GetPoiData>(GET_POI_QUERY, {
    variables: {
      id: poiId
    },
    onCompleted: onPoiLoaded
  });

  const point: Point = getPoiData ? JSON.parse(getPoiData.getPoi.geometry): null;
  const mgrs = point ? new LatLon(point.coordinates[1], point.coordinates[0]).toUtm().toMgrs().toString() : '';

  const degrees = watch('degrees');
  const expireDate = watch('expireDate');
  const expireTime = watch('expireTime');
  const expire = dayjs(`${expireDate} ${expireTime}`);

  const visibilityValue = () => {
    if(visibility === '') {
      return visiblityOptions.find(option => option.id === activeUnit?.id)?.visibility || '';
    } else {
      return visibility;
    }
  }

  const [updatePoi, { loading }] = useMutation(UPDATE_POI_MUTATION);

  const onSubmit = (formData) => {

    const markerProperties: MarkerProperties = {
      name: formData.name,
      angle: formData.degrees,
      icon,
      iconColor
    }

    const updateObservation: UpdatePoi = {
      id: poiId,
      featureType: FeatureType.OBSERVATION,
      visibility: visibilityValue(),
      geometry: getPoiData?.getPoi.geometry || '',
      properties: JSON.stringify(markerProperties),
      expireTime: expire.unix() * 1000
    }

    updatePoi({
      variables: updateObservation
    }).then(() => {
      history.push(`/markers/view/${poiId}`)
    })
  };

  const visibilityChanged = ({ target }) => {
    setVisibility(target.value);
  }

  return (
    <FullPage
      heading={t('markers.edit')}
      loading={loading || getActiveUnitLoading || getPoiLoading}
      closeHandler={() => history.push(`/markers/view/${poiId}`)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label htmlFor={'icon'} hasError={'false'}>
          {t('icon')}
        </Label>
        <GridSelector>
          {markerIconOptions.map(iconOption => (
            <GridSelectorOption key={iconOption.id} selected={iconOption.id === icon} clickHandler={() => setIcon(iconOption.id)}>
              <GridSelectorIconOption style={
                {
                  transform: `rotate(${degrees}deg)`,
                  color: iconColor !== '' ? markerIconColorOptions.find(option => option.id === iconColor)?.hex : '#fff'
                }
              }>
                {iconOption.icon}
              </GridSelectorIconOption>
            </GridSelectorOption>
          ))}
        </GridSelector>

        <Label htmlFor={'iconColor'} hasError={'false'}>
          {t('iconColor')}
        </Label>
        <GridSelector>
          {markerIconColorOptions.map(colorOption => (
            <GridSelectorOption key={colorOption.id} selected={colorOption.id === iconColor} clickHandler={() => setIconColor(colorOption.id)}>
              <GridSelectorColorOption color={colorOption.hex}>
              </GridSelectorColorOption>
            </GridSelectorOption>
          ))}
        </GridSelector>

        <Label htmlFor={'degrees'} hasError={'false'}>
          {t('iconAngle')}
        </Label>
        <input ref={register()} type="range" id="degrees" name="degrees" min="0" max="359" />

        <Label htmlFor={'name'} hasError={'false'}>
          {t('name')}
        </Label>
        <input ref={register({ maxLength: 24 })} type="text" id="name" name="name" placeholder={t('name')} />

        <Label htmlFor={'expireDate'} hasError={errors.expireTime || errors.expireDate ? 'true' : 'false'}>
          {t('expireTime')}{errors.expireTime && ' - ' + t('errors.invalid')}
        </Label>
        <TimeInputContainer>
          <DateInput ref={register({ validate: value => dayjs(value).isValid() })}
                     id={'expireDate'} type={'date'} name={'expireDate'}/>
          <TimeInput
            ref={register({ validate: () => expire.isValid()})}
            id={'expireTime'}
            type={'time'}
            name={'expireTime'}/>
        </TimeInputContainer>

        <Label htmlFor={'visibility'} hasError={'false'}>
          {t('visibility')}
        </Label>
        <select value={visibilityValue()} onChange={visibilityChanged} name="visibility">
          {
            visiblityOptions.map(option => (
              <option key={option.id} value={option.visibility}>{option.name}</option>
            ))
          }
        </select>
        <Label htmlFor={'mgrs'} hasError={'false'}>
          {t('mgrsCoordinates')}
        </Label>
        <input disabled type="text" id="mgrs" name="mgrs" value={mgrs} />

        <input disabled={!formState.isValid || !activeUnit || icon === '' || iconColor === '' || visibilityValue() === ''} type="submit" value={t('markers.update') as string}/>
      </form>
    </FullPage>
  )
}
