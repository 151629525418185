import React, { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import {ActionMenuItem} from '../components/ActionMenuItem';
import {AddIcon, BinocularsIcon} from '../components/icons';
import {useTranslation} from 'react-i18next';

import {LatLon} from 'geodesy/mgrs';
import {ActionList} from '../components/BottomMenu';
import styled from 'styled-components';

const Heading = styled.h3`
  margin: ${props => props.theme.spacing.margin*1.5}px 0!important;
`


export const PointBottomMenu = () => {
  const { t } = useTranslation();
  const { lon, lat } = useParams();

  const mgrs = new LatLon(lat, lon).toUtm().toMgrs().toString()

  return (
    <Fragment>
      <Heading>{mgrs}</Heading>
      <ActionList>
        <ActionMenuItem>
          <BinocularsIcon />
          <Link to={`/observations/create/${lon}/${lat}`}>
            {t('observations.new')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <AddIcon />
          <Link to={`/markers/create/${lon}/${lat}`}>
            {t('markers.new')}
          </Link>
        </ActionMenuItem>
      </ActionList>
    </Fragment>
  )
}
