import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { useOnClickOutside } from '../hooks';

import {
  GET_FACTION_UNITS_QUERY, GET_HIDDEN_UNITS,
  GetFactionUnitsData
} from '../graphql/units';

import { BottomMenu } from '../components/BottomMenu';
import { Orbat } from '../components/Orbat';

import {FullPage} from '../components/FullPage';
import {GET_BODY_HEIGHT} from '../graphql/layout';
import {UnitBottomMenu} from '../units/UnitBottomMenu';

export const OrbatPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { unitId } = useParams();

  const { bodyHeight } = useQuery(GET_BODY_HEIGHT).data;
  const { data: factionUnitsData, loading } = useQuery<GetFactionUnitsData>(GET_FACTION_UNITS_QUERY);
  const { data: hiddenUnitsData } = useQuery(GET_HIDDEN_UNITS);
  const { hiddenUnits } = hiddenUnitsData;

  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const onUnitClick = unit => {
    history.push(`/orbat/view/${unit.id}`)
  }
  const closeHandler = () => {
    history.push(`/orbat`)
  }

  useOnClickOutside(node, closeHandler);

  return (
    <FullPage
      heading={t('orbat')}
      loading={loading}
      closeHandler={() => history.push(`/`)}
    >

      <h2>{t('units.pageHeader')}</h2>

      { factionUnitsData?.getFactionUnits &&
        <Orbat onUnitClick={onUnitClick} hiddenUnits={hiddenUnits} units={factionUnitsData?.getFactionUnits}/>
      }

      <BottomMenu
        refNode={node}
        closeHandler={closeHandler}
        open={unitId}
        maxHeight={bodyHeight}
      >
        <UnitBottomMenu flyToCallback={()=>{}}/>
      </BottomMenu>
    </FullPage>
  )
}
