import { gql } from '@apollo/client';

export interface DevicePositionAttributes {
  batteryLevel: number
  distance: number
  totalDistance: number
  motion: boolean
}

export interface DevicePosition {
  id: number
  deviceId: number
  serverTime: number
  attributes: any
  altitude: number
  latitude: number
  longitude: number
}

export interface Device {
  id: number
  name: string
  attributes: any
  lastUpdate: number
  status: string
  positions?: DevicePosition[]
}

export interface AdminAvailableDevicesData {
  adminGetAvailableDevices: Device[]
}

export interface AdminAttachDeviceToUnitData {
  adminAttachDeviceToUnit: Device
}

export interface AdminDetachDeviceFromUnitData {
  adminDetachDeviceFromUnit: Device
}

export interface GetDevicePositionsData {
  getDevicePositions: DevicePosition[]
}

/*
const DeviceQuery = `
  id
  name
  lastUpdate
`
*/

const GET_DEVICE_POSITIONS = gql`
    query GetDevicePositions($deviceId: ID!) {
      getDevicePositions(deviceId: $deviceId) {
        id
        deviceId
        serverTime
        attributes 
        altitude
        latitude
        longitude
      }
  } 
  `;

const DEVICE_POSITIONS_SUBSCRIPTION = gql`
  subscription OnNewDevicePosition($deviceId: ID!) {
    onNewDevicePosition(deviceId: $deviceId) {
      id
      deviceId
      serverTime
      attributes 
      altitude
      latitude
      longitude
    }
  } 
  `;


const newDevicePositionsHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  const newEvent = subscriptionData.data.onNewDevicePosition;
  const previousPositions = prev.getDevicePositions.slice(0, 2);
  return Object.assign({}, prev, {
    getDevicePositions: [ newEvent, ...previousPositions]
  });
}

export {
  GET_DEVICE_POSITIONS,
  DEVICE_POSITIONS_SUBSCRIPTION,
  newDevicePositionsHandler
}