import React from 'react';
import styled from 'styled-components';

import {Marker} from 'react-map-gl';
import {Keyframe} from '../utils/animations';
import {PushPinIcon} from '../components/icons';

const GeneralMarkerStyled = styled.div`
  color: ${props => props.theme.colors.marker};
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 12px;
    transform: scale(1);
    box-shadow: 0 0 0 0 rgb(255,0,0);
    animation: ${Keyframe.pulseRed} 2s infinite;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
  z-index: 3;
`

interface GeneralMarkerProps {
  latitude: number
  longitude: number
}

export const GeneralMarker = React.memo((props: GeneralMarkerProps) => {
  return (
    <Marker
      latitude={props.latitude}
      longitude={props.longitude}
      anchor={'bottom'}
    >
      <GeneralMarkerStyled>
        <PushPinIcon />
      </GeneralMarkerStyled>
    </Marker>
  )

})
