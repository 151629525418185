import React from 'react';
import styled from 'styled-components';

interface SideMenuStyledProps {
  readonly open: boolean
  bodyHeight: number
};

interface SideMenuProps {
  readonly open: boolean
  bodyHeight: number
  children: any
};

const SideMenuStyled = styled.div<SideMenuStyledProps>`
  position: fixed;
  justify-content: space-between;
  flex-shrink: 0;
  left: 0;
  top: ${props => props.theme.spacing.default*4}px;
  height: ${props => props.bodyHeight - props.theme.spacing.default*4}px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.default};
  padding: 0;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: all 0.2s linear;
  z-index: 5;
  overflow-y: auto;
  
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100vw;
  }
  @media ${({ theme }) => theme.breakpoints.mobileLandscape} {
    width: 360px;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 300px;
    transform: translateX(0);
    position: relative;
  }
`;

export const SideMenu = (props: SideMenuProps) => {
  return (
    <SideMenuStyled open={props.open} bodyHeight={props.bodyHeight}>
      {props.children}
    </SideMenuStyled>
  )
}
