import React from 'react';
import styled from 'styled-components';

interface SwitchProps {
  changeHandler: any
  checked: boolean
};

interface SwitchStyledProps {
  checked: boolean
};

const Slider = styled.span<SwitchStyledProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme, checked}) => checked ? theme.colors.tertiary : theme.colors.background.light};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: ${props => props.theme.spacing.default * 2}px;
  
  &:before {
    position: absolute;
    border-radius: 50%;
    content: "";
    height: ${props => props.theme.spacing.default * 1.5}px;
    width: ${props => props.theme.spacing.default * 1.5}px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    transform: ${({ theme, checked }) => checked ? `translateX(${theme.spacing.default * 1.5}px)`: ''}
  }
`

const SwitchStyled = styled.label`
  position: relative;
  display: inline-block;
  width: ${props => props.theme.spacing.default * 3.5}px;
  height: ${props => props.theme.spacing.default * 2}px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked {
    background-color: ${props => props.theme.colors.tertiary};
  }
  input:focus {
    box-shadow: 0 0 1px ${props => props.theme.colors.tertiary};
  }
  
`;

export const Switch = (props: SwitchProps) => {
  return (
    <SwitchStyled>
      <input type="checkbox" checked={props.checked} onChange={() => props.changeHandler(!props.checked)} />
      <Slider checked={props.checked} />
    </SwitchStyled>
  )
}
