
const awsmobile = {
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_COGNITO_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    "aws_appsync_endpoint": process.env.REACT_APP_AWS_APPSYNC_ENDPOINT,
    "oauth": {}
};

export default awsmobile;