import React, { Fragment, useEffect } from 'react';
import { Route, useParams, useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getSidcFunctionId, getSidcUnitSize} from '../utils/symbols';
import styled from 'styled-components';
import {LatLon} from 'geodesy/mgrs';
import dayjs from 'dayjs';
import {ActionList} from '../components/BottomMenu';
import {useQuery} from '@apollo/client';
import {GET_FACTION_UNIT_QUERY, GetFactionUnitData} from '../graphql/units';
import {DevicePosition, GET_DEVICE_POSITIONS, GetDevicePositionsData} from '../graphql/devices';
import {Switch} from '../components/Switch';
import {addHiddenUnit, removeHiddenUnit, navigationTargetVar} from '../graphql/cache';
import {GET_BODY_HEIGHT, GET_BODY_WIDTH} from '../graphql/layout';
import {DirectionsIcon, MapMarkedAltIcon} from '../components/icons';
import {IconButton} from '../components/IconButton';
import { MapChildProps } from '../map/MapPage';

const ShowOnMapButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  right: ${props => props.theme.spacing.margin}px;
`;

const DataItem = styled.li`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.margin}px;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing.padding}px;
`;

const DataTable = styled.table`
  font-size: ${props => props.theme.font.size.default};
  tr {
    text-align: left;
  }
`

const TdHeading = styled.td`
  padding-right: ${props => props.theme.spacing.padding}px;
  vertical-align: top;
  text-wrap: none;
`

export const UnitBottomMenu = React.memo((props: MapChildProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { unitId } = useParams();
  const { flyToCallback } = props

  const { data: getUnitData, loading: getFactionUnitLoading } = useQuery<GetFactionUnitData>(GET_FACTION_UNIT_QUERY, {
    variables: {
      id: unitId
    }
  });
  const unit = getUnitData?.getFactionUnit;
  const deviceId = unit?.device?.id;
  const { data: getDevicePositionsData, loading: getDevicePositionsLoading } = useQuery<GetDevicePositionsData>(GET_DEVICE_POSITIONS,{
    variables: { deviceId }
  });
  const { bodyHeight } = useQuery(GET_BODY_HEIGHT).data;
  const { bodyWidth } = useQuery(GET_BODY_WIDTH).data;
  const isPortrait = bodyHeight > bodyWidth;

  const loading = getFactionUnitLoading || getDevicePositionsLoading;

  const devicePosition = getDevicePositionsData?.getDevicePositions[0];

  useEffect(() => {
    if(devicePosition) {
      flyToCallback({
        lng: devicePosition.longitude,
        lat: devicePosition.latitude,
        zoom: 14.5
      })
    }
  }, [devicePosition, flyToCallback])

  const size = unit ? t(`units.size.${getSidcUnitSize(unit.sidc)}`) : '';
  const functionId = unit ? t(`units.functionId.${getSidcFunctionId(unit.sidc)}`) : '';

  const mgrs = devicePosition ? new LatLon(devicePosition.latitude, devicePosition.longitude).toUtm().toMgrs().toString() : '';

  const follow = unit ? unit.follow : true;

  const followHandler = () => {
    if(follow) {
      addHiddenUnit(unitId);
    } else {
      removeHiddenUnit(unitId);
    }
  }

  const viewOnMapClickHandler = () => {
    history.push(`/units/view/${unitId}`);
  }

  const navigateToClickHandler = (devicePosition: DevicePosition) => {
    navigationTargetVar({
      name: unit?.name || '',
      point: {
        type: 'Point',
        coordinates: [devicePosition.longitude, devicePosition.latitude]
      }
    });
  }

  return !loading ? (
    <Fragment>
      {
        devicePosition && (
          <ShowOnMapButtonContainer>
            <Route path="/orbat/view/:unitId">
              <IconButton size={'lg'} clickHandler={viewOnMapClickHandler}>
                <MapMarkedAltIcon />
              </IconButton>
            </Route>
            <Route path="/units/view/:unitId">
              <IconButton size={'lg'} clickHandler={() => navigateToClickHandler(devicePosition)}>
                <DirectionsIcon />
              </IconButton>
            </Route>
          </ShowOnMapButtonContainer>
        )
      }

      <h3>{unit?.name}, {unit?.additionalInfo}
        { isPortrait && (<br />)}
        {functionId}, {size}
      </h3>
      <ActionList>
        <DataItem>
          <DataTable>
            <tbody>
            {
              unit?.device? (
                <tr>
                  <TdHeading>{t('follow')}:</TdHeading>
                  <td><Switch changeHandler={followHandler} checked={follow} /></td>
                </tr>
              ) : (
                <tr>
                  <TdHeading>{t('tracker')}:</TdHeading>
                  <td>{unit?.device? unit.device.name : t('noTracker')}</td>
                </tr>
              )
            }
            {
              devicePosition && (
                <Fragment>
                  <tr>
                    <TdHeading>MGRS:</TdHeading>
                    <td>{mgrs}</td>
                  </tr>
                  <tr>
                    <TdHeading>{t('lastUpdate')}:</TdHeading>
                    <td>{dayjs(devicePosition?.serverTime).format('HH:mm:ss DD.MM')}</td>
                  </tr>
                </Fragment>
              )
            }
            {
              unit?.user && (
                <tr>
                  <TdHeading>{t('user')}:</TdHeading>
                  <td>{unit?.user?.name}</td>
                </tr>
              )
            }
            </tbody>
          </DataTable>
        </DataItem>
      </ActionList>
    </Fragment>
  ) : null;
})
