import React from 'react';
import { useHistory } from 'react-router-dom';
import { MapboxEvent, Marker } from 'react-map-gl';
import {getFillOpacityByExpireTime, sidcToMilSymbol} from '../utils/symbols';
import styled from 'styled-components';
import {ObservationProperties, Poi} from '../graphql/pois';
import {Point} from 'geojson';

interface ObservationMarkerProps {
  poi: Poi
}

const ObservationSymbol = styled.img`
`;

export const ObservationMarker = React.memo((props: ObservationMarkerProps) => {
  const history = useHistory();
  const { poi } = props;
  const properties: ObservationProperties = JSON.parse(poi.properties);
  const geometry: Point = JSON.parse(poi.geometry);
  const fillOpacity = poi.expireTime ? getFillOpacityByExpireTime(poi.expireTime) : 1;
  const milsymbol = sidcToMilSymbol(properties.sidc, '', '', false, fillOpacity);
  const svg = milsymbol.asSVG();
  const anchor = milsymbol.getAnchor();

  const clickHandler = (evt: MapboxEvent<MouseEvent>) => {
    history.push(`/observations/view/${poi.id}`)
    evt.originalEvent.preventDefault()
    evt.originalEvent.stopPropagation()
  }

  return (
    <Marker
      latitude={geometry.coordinates[1]}
      longitude={geometry.coordinates[0]}
      anchor={'top-left'}
      offset={[-anchor.x,-anchor.y]}
      onClick={evt => clickHandler(evt)}
    >
      <ObservationSymbol src={`data:image/svg+xml;utf8,${svg}`} />
    </Marker>
  );
})
