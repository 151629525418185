import { gql } from '@apollo/client';

export interface LimitedUser {
  id: string
  name: string
}

export interface User {
  id: string
  name: string
  email: string
  created: number
}

export interface GetUserData {
  getUser: User
}

const GET_USER_QUERY = gql`
  query GetUser {
      getUser { 
        id
        name
        email
        created
      }
    } 
  `;

export { GET_USER_QUERY };