import React from 'react';
import styled from 'styled-components';
import { FullPage } from '../components/FullPage';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Delete } from '@styled-icons/material/Delete';

import { useTranslation } from 'react-i18next';
import {Button} from '../components/Button';

import {DELETE_POI_MUTATION, GET_POI_QUERY, GetPoiData, MarkerProperties} from '../graphql/pois';
import {markerIconColorOptions, markerIconOptions} from '../components/icons';

const StyledDeletePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DeleteIcon = styled(Delete)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

const MarkerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.margin*2}px;
`;

const MarkerSymbol = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: ${props => props.theme.font.size.small};
  }
`;

export const DeleteMarkerPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { poiId } = useParams();

  const { data: getPoiData, loading: getPoiLoading } = useQuery<GetPoiData>(GET_POI_QUERY, {
    variables: {
      id: poiId
    }
  });

  const properties: MarkerProperties = getPoiData ? JSON.parse(getPoiData.getPoi.properties) : null;

  const IconComponent = properties ? markerIconOptions.find(option => option.id === properties.icon)?.icon : null;
  const iconColorHex = properties ? markerIconColorOptions.find(option => option.id === properties.iconColor)?.hex : '#fff';
  const angle = properties ? properties.angle : 0;

  const [deletePoi, { loading: deleting }] = useMutation(DELETE_POI_MUTATION);

  const onDelete = () => {
    deletePoi({
      variables: { id: poiId }
    })
      .then(() => {
        history.push(`/`)
      })
  }

  return (
    <FullPage
      heading={properties?.name}
      loading={deleting || getPoiLoading}
      closeHandler={() => history.push(`/markers/view/${poiId}`)}
    >
      <StyledDeletePage>
        <h2>{t('markers.delete')}?</h2>
        <MarkerContainer>
          <MarkerSymbol style={{
            color: iconColorHex,
            transform: `rotate(${angle}deg)`,
          }}>
            {IconComponent}
            <span>{properties?.name}</span>
          </MarkerSymbol>
        </MarkerContainer>
        <Button clickHandler={onDelete} size={'lg'}><DeleteIcon /> {t('delete')}</Button>
      </StyledDeletePage>
    </FullPage>
  )
}
