import React from 'react';
import styled from 'styled-components';
import logoWithText from './logoWithText.svg';

const Image = styled.img`
  margin: ${props => props.theme.spacing.margin}px;
  height: ${props => props.theme.spacing.default*3}px;
`;

export const LogoWithText = (props: any) => {
  return (
    <Image src={logoWithText} alt="logo" />
  )
}
