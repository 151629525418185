import React from 'react';
import { useHistory } from 'react-router-dom';
import {Marker} from 'react-map-gl';
import styled from 'styled-components';
import {MarkerProperties, Poi} from '../graphql/pois';
import {Point} from 'geojson';
import {markerIconColorOptions, markerIconOptions} from '../components/icons';

interface MarkerMarkerProps {
  poi: Poi
}

interface MarkerSymbolProps {
  name: string
  angle: number
}

const MarkerSymbol = styled.div<MarkerSymbolProps>`
  display: flex;
  flex-direction: column;
  &:after {
    content: '${props => props.name}';
    position: absolute;
    transform: rotate(${props => -props.angle}deg);
    bottom: 25px;
    font-size: 13pt;
  }
`;

export const MarkerMarker = React.memo((props: MarkerMarkerProps) => {
  const history = useHistory();
  const { poi } = props;
  const properties: MarkerProperties = JSON.parse(poi.properties);
  const geometry: Point = JSON.parse(poi.geometry);

  const clickHandler = (event) => {
    history.push(`/markers/view/${poi.id}`)
    event.preventDefault()
    event.stopPropagation()
  }

  const IconComponent = markerIconOptions.find(option => option.id === properties.icon)?.icon;
  const iconColorHex = markerIconColorOptions.find(option => option.id === properties.iconColor)?.hex;
  const angle = properties.angle;

  return (
    <Marker
      latitude={geometry.coordinates[1]}
      longitude={geometry.coordinates[0]}
      anchor={'center'}
    >

      <MarkerSymbol angle={angle} name={properties.name} onClick={event => clickHandler(event)} style={{
        color: iconColorHex,
        transform: `rotate(${angle}deg)`,
      }}>
        {IconComponent}

      </MarkerSymbol>
    </Marker>
  );
})

